import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';

import {ThemeProvider, Typography, ToggleButton, ToggleButtonGroup} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";


const VariableList = (props) => {

    const location = useLocation();
    const [pageApprove, setPageApprove] = useState(false)

    useEffect(() => {
        switch (location.pathname) {
            case "/approve":
                setPageApprove(true)
            break;
            default:
                setPageApprove(false)
            break;
        }
    }, [location]);



    return(
        <ThemeProvider theme={ThemeCustom}>
        {props.variablesSet.length > 0 && props.variablesSet.map((item, i) => (
            <React.Fragment key={i}>
                <Typography component={'div'} variant='text20' className='mb-3 font-semibold pt-4'>{item.key}</Typography>
                    <ToggleButtonGroup
                        value={props.variables}
                        onChange={props.handleMappings}
                        orientation="vertical"
                        aria-label="mapping variables"
                        className='w-full'
                    >
                        {Object.entries(item).map(([name, data], i)=> (
                            (name !== 'key') ? (
                                <ToggleButton 
                                    key={i} 
                                    value={data.value} 
                                    disableRipple={true} 
                                    onClick={() => props.handleItemClick(data.value)}
                                    className={`${pageApprove ? 'mappingsVar' :'knownMapVar'}`}
                                    disabled={pageApprove}
                                >
                                    {name}
                                </ToggleButton>
                            ):''
                        ))}    
                    </ToggleButtonGroup>
            </React.Fragment>
        ))}
        </ThemeProvider>
    )
}

export default VariableList;