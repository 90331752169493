import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklOrgMain = () => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='flex items-center border border-borderGrayDark border-solid px-6 rounded-[1.25rem] mb-4'>
                <Skeleton animation="wave" className={`min-w-[4rem] h-[4rem] rounded-full`}></Skeleton>   
                <div className='flex justify-between items-center py-5 border-0 border-borderGrayDark border-solid w-full ml-5'>
                        <div>
                            <Skeleton animation="wave" className={` w-[12rem] h-[1.5rem] mb-1 rounded`}></Skeleton>    
                            <Skeleton animation="wave" className={` w-[15rem] h-[1.25rem] mb-1 rounded`}></Skeleton>    
                        </div>
                    <div className='flex'>
                        <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] mr-4 rounded`}></Skeleton>    
                        <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] mr-4 rounded`}></Skeleton>   
                        <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] rounded`}></Skeleton> 
                        <div className='border-r border-0 border-solid border-borderGrayDark pr-6 mr-6'></div>
                        <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] rounded`}></Skeleton>  
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default SklOrgMain;

