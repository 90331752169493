import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from 'simplebar-react';

import {ThemeProvider, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Tooltip, IconButton} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

import NotFound from '../masseges/Not_found';
import SklTable from '../skeletons/Skl_table';

import EditIcon from '../../assets/edit-new.svg'
import DeleteIcon from '../../assets/delete.svg'

const RegionTable = (props) => {

    const [notFound, setNotFound] = useState(false);
    const [loader, setLoader] = useState(false);
 
    
    useEffect(()=>{
        console.log('not found',props.country);
        if(props.country.length === 0){
            setNotFound(true)
            console.log('not found if',props.country);
        }
        else{
            setNotFound(false)
            console.log('not found else',props.country);
        }
    },[props.country])

    //editRegion

    const editRegion = (id) => {
        console.log('editRegion id - ', id)
        props.setEditId(id)
        props.setOpenAddRegion(true)
    }

    //deleteRegion

    const deleteRegion = (id) => {
        console.log('deleteRegion id - ', id)
        props.setOpenDelete(true)
        props.setEditId(id)
    }


    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className={` relative border-solid border border-borderGrayDark rounded-xl`}>
                <SimpleBar className='min-h-region rounded-xl'>
                {props.loading ? (  
                    <SklTable 
                        tableRow={9}
                        tableCol={2}
                        hasActions={2}
                    />
                ): notFound  ? (  
                    <NotFound msgText={'No regions and countries found!'}/>
                ):(
                    <TableContainer >
                        <Table stickyHeader aria-label="unknown map Datasets">  
                            <TableHead>
                                <TableRow>
                                    <TableCell>Region</TableCell>
                                    <TableCell>Countries</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { props.country.length > 0 && props.country.map((country, index) => (
                                <TableRow key={index} >
                                    <TableCell className='max-w-[15vw] truncate cursor-pointer'>
                                        {/* <Tooltip title={country.region} arrow placement="bottom">
                                            <span>{country.region}</span>
                                        </Tooltip> */}
                                        {country.name}
                                    </TableCell>
                                    <TableCell className='max-w-[30vw] truncate cursor-pointer'>
                                        <Tooltip title={country.countries.map((item, i)=> `${item.name}${country.countries.length === i+1 ? '':', '}`)} arrow placement="bottom">
                                            <span>{country.countries.map((item, i)=> `${item.name}${country.countries.length === i+1 ? '':', '}`)}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={`max-w-[10rem]`}>
                                        <IconButton  onClick={()=>editRegion(country.id)} className='mr-4'>
                                            <img src={EditIcon} alt='icon' className='w-5'/>
                                            </IconButton>
                                        <IconButton  onClick={()=>deleteRegion(country.id)} className='mr-4'>
                                            <img src={DeleteIcon} alt='icon' className='w-5'/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                </SimpleBar>
            </div>
        </ThemeProvider>
    );
}

export default RegionTable;