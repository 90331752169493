import React, {useEffect, useState} from 'react';
import {Link } from 'react-router-dom';

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";

import loaderIcon from '../assets/Loading1.svg'
import notAllowIcon from '../assets/not-allow.gif'

const Loading = (props) => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="h-screen flex justify-center items-center flex-col">
                <img className="max-w-[3.75rem] min-h-[3.75rem] mb-8" src={loaderIcon} alt="loading logo"/>
                <Typography variant="text30" className="font-semibold mb-7" component='div'>
                    Loading...
                </Typography>
            </div>
        </ThemeProvider>
    )
}

export default Loading;