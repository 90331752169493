import React, {useState, useEffect, useRef} from 'react';
import { useBaseUrlContext } from '../context/Base_url';
import { useUserContext } from '../context/User_Access';
import { useAuthContext } from '../context/Auth';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import useAxiosInstance from '../api/interceptor';

import {ThemeProvider, Container, Typography, FormControl, OutlinedInput, InputAdornment, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';

import ButtonImg from '../components/button/Btn_img';

import { ThemeCustom} from "./../styles/Theme";
import '../components/file-upload/Drag-input_styles.scss';

import SearchIcon from '../assets/search.svg'
import importIcon from '../assets/upload.svg'
import CheckIcon from '../assets/tick-select.svg'; 
import UncheckIcon from '../assets/tick-notselect.svg'; 

import DocSummary from '../components/summary_table/Doc_summary';
import AddColumnModal from '../components/modal/Modal_add_columns';
import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';
import SklHeaderText24 from '../components/skeletons/Skl_header-text24';
import SklButton from '../components/skeletons/Skl_button';
import SklInput from '../components/skeletons/Skl_input';
import Loading from './Loading';

const filters = [{name:'All documents', value:''}, {name:'Visual documents', value:'other'}, {name:'Structured documents', value:'xml'}]
const region = [{name:'Northern Europe', value:''}, {name:'Visual documents', value:'other'}, {name:'Structured data files', value:'xml'}]


const DocReview = (props) => {
    const [files, setFiles] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('');
    const [filterImport, setFilterImport] = useState(filters);
    const [filterName, setFilterName] = useState('All documents')
    const [regionFilterImport, setRegionFilterImport] = useState([])
    const [regionFilter, setRegionFilter] = useState([])
    const [regionNames, setRegionNames] = useState([])
    const [regionFilterSting, setRegionFilterSting] = useState(null)
    const [allselFilterName, setAllselFilterName] = useState('')
    const [openAddColumn, setOpenAddColumn] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState('');
    const [sklCommon, setSklCommon] = useState(true);
    const [sklRegion, setSklRegion] = useState(true);
    const [loadingComp, setLoadingComp] = useState(false);
    const {BaseUrl} = useBaseUrlContext()
    const navigate = useNavigate();
    const {devMode} = useUserContext()
    const {tokenReview, unAuthAccess, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();

    useEffect(()=>{console.log('regionFilterSting',regionFilterSting)},[regionFilterSting])

    //page skeleton handiling

    useEffect(() => {
    
        setTimeout(()=>{
           setSklCommon(false)
        },200)

         sessionStorage.removeItem('savedPdf')

    },[]) 

    //unAuthAccess

    useEffect(() => {
        //console.log('navigate xxx');
        unAuthAccess()
    }, [navigate]);

    useEffect(() => {
         
        if(files.length > 0){
            setDisabled(false);
            console.log('files inv', files);
        }
        else{
            setDisabled(true);
        }
 
         console.log('files', files)
         
    },[files]);

    useEffect(()=>{//console.log('filter---',filter)
    },[filter])

    //fail alert

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },5000)
    },[openFail]);

    //success alert

    useEffect(()=>{
        //console.log(openSuccess)
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    useEffect(()=>{
        //console.log('searchTerm',searchTerm)
    },[searchTerm])

    const backToUpload = () => {

        if(devMode){
            navigate('/?devMode=true')
        }
        else{
            navigate('/file_upload')
        }
    }

    const filterApply = (e) =>{
        //console.log('filter', e.target.value)
        setFilter(e.target.value);
        const selectedFilter = filters.find(filter => filter.value === e.target.value);

        setFilterName(selectedFilter.name)
    }

    const RegionFilterApply = (e) => {
        //console.log('RegionFilterApply', e.target.value)

        const selectedNames = regionFilterImport
        .filter(region => e.target.value.includes(region.id))
        .map(region => region.name);
        setRegionNames(selectedNames)
        //console.log('Selected Region Names:', selectedNames);

        //select all

        if(e.target.value.includes('all')){
        
            if(regionFilter.length  > e.target.value.length){
                const removeAll = e.target.value.filter(region => region !== 'all')
                setRegionFilter(removeAll);
                setUserPreference(removeAll)
                //console.log('RegionFilterApply else else')
            }
            // else if(regionFilterImport.length  === e.target.value.length){
            //     const removeAll = e.target.value.filter(region => region !== 'all')
            //     setRegionFilter(removeAll);
            //     console.log('RegionFilterApply else else')
            // }
            else{
                const selAllVal = regionFilterImport.map(region => region.id)
                const selAllName = regionFilterImport.map(region => region.name)
                setRegionFilter([...selAllVal, 'all']);
                setUserPreference([...selAllVal, 'all'])
                setRegionNames(selAllName)
            }
  
        }
        else{
            
            if(regionFilterImport.length  === e.target.value.length){

                if(regionFilter.includes('all') && !e.target.value.includes('all')){
                    setRegionFilter([]);
                    setUserPreference([])
                    setRegionNames([])
                }
                else{
                    const selAllVal = regionFilterImport.map(region => region.id)
                    const selAllName = regionFilterImport.map(region => region.name)
                    setRegionFilter([...selAllVal, 'all']);
                    setUserPreference([...selAllVal, 'all'])
                    setRegionNames(selAllName)
                }
                
            }
            else{
                const removeAll =  e.target.value.filter(region => region !== 'all')
                setRegionFilter(removeAll);
                setUserPreference(removeAll)
                //console.log('selAll else removeAll', removeAll)
            }

        }    
        

    }


    useEffect(()=>{

        getRegion()

    },[])

    //get region list

    const getRegion = async() => {

        try{

            setSklRegion(true)

            const res = await axiosInstance.get(`/region.php`)

            let data = res.data.result;
            let error = res.data.error;

            setRegionFilterImport(data.region)
            setTimeout(()=>{
                setSklRegion(false)
            },1000)

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    //get regions from local storage

    useEffect(() => {

        const regionIds = regionFilterImport?.map(region => region.id);
        const regionNames = regionFilterImport?.map(region => region.name);
        
        const storedFilter = JSON.parse(localStorage.getItem('regionFilter') || '[]');
        const isAllRegionsSelected = storedFilter.length === regionFilterImport.length;
        
        if (storedFilter.length === 0) {
            
            if(regionIds?.length > 0){
                setRegionFilter([...regionIds, 'all']);
                setUserPreference(regionIds)
                setRegionNames(regionNames);
            }
            
        } else {
            const selectedNames = regionFilterImport
            .filter(region => storedFilter.includes(region.id))
            .map(region => region.name);
            
            setRegionFilter(isAllRegionsSelected ? [...storedFilter, 'all'] : storedFilter);
            setRegionNames(selectedNames);
        }
        
    }, [regionFilterImport]);
    

    //create selected fileters name list

    useEffect(()=>{
        //console.log('filterName---///',filterName)

        const joinFilterArrays = [filterName, ...regionNames]
        const ArrayString = joinFilterArrays.join('  •  ')
        setAllselFilterName(ArrayString)
    },[filterName, regionNames])

    //create sting for region

    useEffect(()=>{
        console.log('regionFilter---xxx',regionFilter)
        const RegionSend = regionFilter.filter(region => region !== 'all')
        //console.log('RegionSend',RegionSend)
        const ArrayString = RegionSend.join(',')
        if(regionFilterImport.length > 0){
            setRegionFilterSting(ArrayString)
        }
        //console.log('ArrayString---',ArrayString)
        
        //set selected region filters in localhost

        if(regionFilterImport.length > 0){
            console.log('regionFilter###', regionFilter, regionFilter.filter(item => item !== 'all'))
            localStorage.setItem('regionFilter', JSON.stringify(regionFilter.filter(item => item !== 'all')))
        }

    },[regionFilter])

    //set user preference

    const setUserPreference = async(selFilter) => {

        try {

            let data = {
                documentReview: {
                    "regions": selFilter,
                    "selectedColumns": []
                }
            }

            console.clear()
            console.log('setUserPreference', data)

            const res = await axiosInstance.post(`/user_preferences.php`, data)
                
            let result = res.data.result;
            let error = res.data.error;

            console.log('setUserPreference', result.message)

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    return(
        <ThemeProvider theme={ThemeCustom}>
             <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
            <AddColumnModal setOpenAddColumn={setOpenAddColumn} openAddColumn={openAddColumn}/>
            {loadingComp ? (<Loading/>) : 
            (
                <div className='mt-[4rem] container-up-margin'>
                    <Container maxWidth="false">
                        <div className='flex justify-between md:items-center flex-col md:flex-row'>
                            {sklCommon?(
                                <SklHeaderText24/>
                            ):(
                                <Typography variant="text24" className="font-medium py-8" component='div'>
                                    Review your business documents
                                </Typography>
                            )}    
                            <div className='flex items-center pb-8 md:pb-0'>
                            <FormControl variant="standard"  margin="normal" className='m-0 w-full xl:min-w-[25rem] mr-4'>
                            {sklCommon?(
                                <SklInput/>
                                ):(
                                    <OutlinedInput className='search-input rounded-xl'
                                        autoComplete="off" 
                                        placeholder="Search" 
                                        type='text'
                                        value={searchTerm}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt='icon' className='w-5'/>
                                            </InputAdornment>
                                        }
                                        onChange={(e) => {setSearchTerm(e.target.value)}}
                                    />
                                )}
                                </FormControl>
                                <FormControl margin="normal" className='m-0 w-full lg:min-w-[12rem] xl:min-w-[18rem] mr-4'>
                                    {sklRegion?(
                                        <SklInput/>
                                    ):(
                                    <Select
                                        className='select type-filter-select max-w-[18rem] region-select'
                                        value={regionFilter}
                                        onChange={RegionFilterApply}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Region' }}
                                        multiple
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                            return (<span className='text-sonicSilver'>Regions: </span>);
                                            }
                                            const selectedNames = regionFilterImport
                                            .filter(region => selected.includes(region.id))
                                            .map(region => region.name);
                                            return (<><span className='text-sonicSilver'>Regions: </span> {selectedNames.join(', ')}</>);
                                        }}
                                    >
                                        <MenuItem value={'all'} className='type-filter-item review-doc'>
                                            <div className='type-filter-item-filter filter-hide'>Regions:</div>
                                            <Checkbox 
                                                checked={regionFilter.indexOf('all') > -1} 
                                                disableTouchRipple={true}
                                                disableRipple={true}
                                                checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                                icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                                className='dropdown-checkbox filter-chk-hide'
                                            />
                                            Select All
                                        </MenuItem>
                                        { regionFilterImport.length > 0 && regionFilterImport.map((filterItem, index) => (
                                            <MenuItem key={index} value={filterItem.id} className='type-filter-item review-doc'>
                                                <div className='type-filter-item-filter filter-hide'>Regions:</div>
                                                <Checkbox 
                                                    checked={regionFilter.indexOf(filterItem.id) > -1} 
                                                    disableTouchRipple={true}
                                                    disableRipple={true}
                                                    checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                                    icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                                    className='dropdown-checkbox filter-chk-hide'
                                                />
                                                {filterItem.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    )}
                                </FormControl>
                                <FormControl margin="normal" className='m-0 w-full lg:min-w-[12rem] xl:min-w-[18rem] mr-4'>
                                {sklCommon?(
                                    <SklInput/>
                                    ):(
                                        <Select
                                            className='select type-filter-select max-w-[18rem]'
                                            value={filter}
                                            onChange={filterApply}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Document Type' }}
                                        >
                                            { filterImport.length > 0 && filterImport.map((filter, index) => (
                                                <MenuItem key={index} value={filter.value} className='type-filter-item review-doc'><div className='type-filter-item-filter filter-hide'>Filter by:</div>{filter.name}</MenuItem>
                                            ))}
                                        </Select>
                                    )
                                }    
                                </FormControl>
                                {sklCommon ? (
                                    <SklButton/>
                                ):(
                                    <ButtonImg btnName="Upload More" img={importIcon} type="button" classes=" min-w-[11.5rem] px-3" onClick={backToUpload}/>
                                )}
                            </div>
                        </div>
                        <DocSummary 
                            filter={filter} 
                            searchTerm={searchTerm} 
                            setSearchTerm={setSearchTerm} 
                            uploading={props.uploading} 
                            submitFiles={props.submitFiles} 
                            uploadFiles={props.uploadFiles} 
                            uploadCompeted={props.uploadCompeted}
                            setOpenAddColumn={setOpenAddColumn}
                            allselFilterName={allselFilterName}
                            regionFilterSting={regionFilterSting}
                            devMode={devMode}
                            setTextFail={setTextFail}
                            setOpenFail={setOpenFail}
                            regionFilterImport={regionFilterImport}
                            setOpenSuccess={setOpenSuccess}
                            setTextSuccess={setTextSuccess}
                            DocSummary={props.DocSummary}
                            sklCommon={sklCommon}
                            sklRegion={sklRegion}
                            setLoadingComp={setLoadingComp}
                        />
                        
                    </Container> 
                </div>

            )}
        </ThemeProvider>
    )
}

export default DocReview;