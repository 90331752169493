import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { Typography } from '@mui/material';
import SklSwiper from '../skeletons/Skl_swiper';

import processeDoc from '../../assets/clipboard-text.svg';
import modifiedDoc from '../../assets/clipboard-tick.svg';
import unmodifiedDoc from '../../assets/clipboard-close.svg';
import accuracyDoc from '../../assets/discount-circle.svg';
import nextArrow from '../../assets/arrow-next.svg';

const ReportSwiper = (props) => {


    return (
        <Swiper
            modules={[Navigation]}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            navigation
            breakpoints={{
                900: {
                    slidesPerView: 2,
                    spaceBetween: 12
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 12
                },
                1520: {
                    slidesPerView: 4,
                    spaceBetween: 12
                }
            }}
        >
            <SwiperSlide >
                {!props.totalDocumentsProcessedCount  && props.totalDocumentsProcessedCount < 0 ? (
                    <SklSwiper/>
                ):(
                <div className='border-borderGrayDark border border-solid rounded-2xl p-5'>
                    <div className='flex items-start'>
                        <img src={processeDoc} alt='processed documents' className='w-full max-w-[1.5rem] bg-secondary bg-opacity-20 p-4 rounded-full'/>
                        <div className='ml-5 flex flex-col max-w-[72%]'>
                            <Typography component={'div'} variant='textBase' className='text-sonicSilver mb-2 truncate max-w-full'>Tot. documents processed</Typography>
                            <Typography variant='text30' component={'div'} >{props.totalDocumentsProcessedCount}</Typography>
                        </div>
                    </div>
                </div>
                )}
            </SwiperSlide>
            <SwiperSlide >
                {!props.modifiedDocumentsCount && props.modifiedDocumentsCount < 0 ? (
                    <SklSwiper/>
                ):(
                <div className='border-borderGrayDark border border-solid rounded-2xl p-5'>
                    <div className='flex items-start'>
                        <img src={modifiedDoc} alt='Modified documents' className='w-full max-w-[1.5rem] bg-[#2CAEF6] bg-opacity-20 p-4 rounded-full'/>
                        <div className='ml-5 flex flex-col  max-w-[72%]'>
                            <Typography component={'div'} variant='textBase' className='text-sonicSilver mb-2 truncate max-w-full'>Modified documents</Typography>
                            <Typography component={'div'} variant='text30'>{props.modifiedDocumentsCount}</Typography>
                        </div>
                    </div>
                </div>
                )}
            </SwiperSlide>
            <SwiperSlide >
                {!props.unModifiedDocumentsCount && props.unModifiedDocumentsCount < 0 ? (
                    <SklSwiper/>
                ):(
                <div className='border-borderGrayDark border border-solid rounded-2xl p-5'>
                    <div className='flex items-start'>
                        <img src={unmodifiedDoc} alt='Unmodified documents' className='w-full max-w-[1.5rem] bg-fulvous bg-opacity-20 p-4 rounded-full'/>
                        <div className='ml-5 flex flex-col  max-w-[72%]'>
                            <Typography component={'div'} variant='textBase' className='text-sonicSilver mb-2 truncate max-w-full'>Unmodified documents</Typography>
                            <Typography component={'div'} variant='text30'>{props.unModifiedDocumentsCount}</Typography>
                        </div>
                    </div>
                </div>
                )}
            </SwiperSlide>
            <SwiperSlide >
                {!props.accuracy  && props.accuracy < 0 ? (
                    <SklSwiper/>
                ):(
                <div className='border-borderGrayDark border border-solid rounded-2xl p-5'>
                    <div className='flex items-start'>
                        <img src={accuracyDoc} alt='Accuracy documents' className='w-full max-w-[1.5rem] bg-[#6462FC] bg-opacity-20 p-4 rounded-full'/>
                        <div className='ml-5 flex flex-col  max-w-[72%]'>
                            <Typography component={'div'} variant='textBase' className='text-sonicSilver mb-2 truncate max-w-full'>Accuracy %</Typography>
                            <Typography component={'div'} variant='text30'>{props.accuracy}%</Typography>
                        </div>
                    </div>
                </div>
                 )}
            </SwiperSlide>
        </Swiper>
    );
}

export default ReportSwiper;