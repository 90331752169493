import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';


const ConCurResponseModal = ({openConcurResponse, setOpenConcurResponse, requesterName, requesterId, webSocketResponseAcessDeny, webSocketResponseAcessAllow}) =>{

    const [countDown, setCountDown] = useState(15)

    const approve = () =>{

        console.log('Access granted');
        webSocketResponseAcessAllow(requesterId);
        setOpenConcurResponse(false)

    }

    const deny = () =>{

        console.log('Access deny');
        webSocketResponseAcessDeny(requesterId)
        setOpenConcurResponse(false)

    }

    //set timmer to auto grant access
    
    useEffect(()=>{

        if(openConcurResponse){

            if (countDown < 1) {
                setOpenConcurResponse(false)
                webSocketResponseAcessAllow(requesterId)
                setCountDown(15)
                return; 
            }
    
            const Timmer = setInterval(()=>{
                setCountDown(prevSeconds => prevSeconds -1)
            },1000)
    
            return () => clearInterval(Timmer);

        }


    },[countDown, openConcurResponse])

    return(
        <div>
            <Modal
                open={openConcurResponse}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-disconnect']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={'text-heading-main'}>
                                {countDown}s
                            </div>
                                <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                {requesterName} has requested edit access
                                </Typography>
                                <Typography id="modal-modal-description" className={ModalStyle['delete-file']} align={'center'} variant="textBase" component="p">
                                You have {countDown} seconds to respond or access will be automatically granted.
                                </Typography>
                        </div>
                        <div className={`${ModalStyle['footer-wrapper-center']}`}>
                            <ButtonSecondary btnName="Approve" type="button" variant={'primaryBtn'} classes="mr-1" onClick={approve} disabled={false}/>
                            <ButtonSecondary btnName="Deny" variant={'redBtn'} type="button" classes="ml-1" onClick={deny} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default ConCurResponseModal;