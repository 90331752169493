import React, {useState, useEffect} from 'react';

import { ThemeProvider, Button, FormControl, Select, MenuItem, OutlinedInput, InputAdornment } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";
import './Add-dropdown.scss'

import SearchIcon from '../../assets/search.svg'
  

const AddDropdown = (props) => {

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownItem, setDropdownItem] = useState([]);
    const [searchFile, setSearchFile] = useState([]);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        console.log('files preveiw dd', props.fieldNameList);
        setDropdownItem(props.fieldNameList)
        setSearchFile(props.fieldNameList)
        //console.log('files preveiw xxxxx', searchFile, dropDownItem);
    }, [props.fieldNameList]);

    useEffect(()=>{
        if(dropDownItem.length > 0){
            const filteredItems = dropDownItem.filter((item) =>
            item.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchFile(filteredItems);
            //console.log('filteredItems', filteredItems);
        }
    },[searchTerm])


    useEffect(() => {
        //console.log("searchFile.length",searchFile.length);
        if(dropDownItem.length !== 0 && searchFile.length === 0){
            setNotFound(true)
        }
        else{
            setNotFound(false)
        }
    }, [searchFile])

    const handleClose = () => {
        setDropDownOpen(false);
        setTimeout(()=>{
            setSearchTerm('');
        },1000)
    };
    
    const handleOpen = () => {
        setDropDownOpen(true);
    };

    const handleChange = (e) => {

        if(!(e.target.value[e.target.value.length - 1] === undefined && e.target.value.length === 1)){
            //props.setAddField(e.target.value);
            setSearchFile(props.fieldNameList)
            setSearchTerm('');
            //comming soon
            setDropDownOpen(false);
            props.setOpenCommingSoon(true)
        }
        
        //console.log('target', e.target.value, e.target.value.length, e)
    };

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='flex flex-col justify-end items-center'>
                <Button disabled={props.readonly} variant='imgBtn' disableRipple={true} className={`text-sm p-0 text-[#757575] ms-1 min-w-min hover:bg-white ${props.readonly? 'opacity-50':''}`} onClick={handleOpen}>+ Add Field</Button>
                <FormControl className='invisible h-0 w-0 m-0 addField'>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={props.addField}
                        open={dropDownOpen}
                        onChange={handleChange}
                        onClose={handleClose}
                        onOpen={handleOpen}
                    >
                        <div>
                            <FormControl variant="standard"  margin="normal" className='dropdown-serach'>
                                <OutlinedInput className='search-input rounded-xl'
                                    autoComplete="off" 
                                    placeholder="Search" 
                                    type='text'
                                    value={searchTerm}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt='icon' className='icon-w-5'/>
                                        </InputAdornment>
                                    }
                                    onChange={(e) => {setSearchTerm(e.target.value)}}
                                    onKeyDown={(e) => e.stopPropagation()}
                                />
                            </FormControl>
                        </div>
                        {dropDownItem.length > 0 && searchFile.map((name) => (
                            <MenuItem
                                onKeyDown={(e) => e.stopPropagation()}
                                className='fieldDropdownItem'
                                key={name}
                                value={ `${props.keyCat}:${name}`}                            
                            >
                            {name}
                            </MenuItem>
                        ))}
                        {notFound ? (
                        <MenuItem
                            onKeyDown={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                            disableRipple                            
                            className='fieldDropdownItem no-bg-img'
                        >
                            No result found!
                        </MenuItem>
                        ):('')}
                    </Select>
                </FormControl>
            </div>    
        </ThemeProvider>    
    );
}

export default AddDropdown;