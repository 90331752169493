import React from "react";

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import InputStyle from './Input_styles.module.scss';
import './Drag-input_styles.scss';

import UploadAnimate  from "../../assets/upload-animation.gif";

// Register the plugins
registerPlugin(FilePondPluginFileValidateType);


const DragDropMore = (props) => {


    return (
        <ThemeProvider theme={ThemeCustom}>
            <div className="w-full rounded-xl bg-secondary bg-opacity-10" >
                <div className={`${InputStyle["file-upload-wrapper"]} ${InputStyle["upload-more"]} file-pond-custom upload-more-custom upload-screen relative`}>
                    <form onSubmit={props.submit}>
                        <div className={`${InputStyle["file-upload-container"]} flex items-end  min-h-[calc(100vh-17.5rem)]`}>
                            <FilePond
                                files={props.files}
                                allowReorder={false}
                                allowMultiple={true}
                                //acceptedFileTypes={['']}
                                onupdatefiles={props.setFiles}
                                labelIdle=''
                            />
                            {/* <div className=" bottom-8 translate-x-1/2M left-1/2 absolute z-0"> */}
                                <div className="flex items-center bg-primary p-2 rounded-full max-w-[27rem]">
                                    <div className="flex justify-center items-center p-2 bg-secondary mr-5 rounded-full">
                                        <img className="max-w-[2.5rem] min-h-[2.5rem]" src={UploadAnimate} alt="search logo"/>
                                    </div>
                                    <Typography variant="textBase" className="font-medium text-white pr-8" component='div'>
                                        Drag and drop files here to upload                               
                                    </Typography>
                                </div>
                            {/* </div> */}
                        </div>
                    </form>
                </div>
            </div>
        </ThemeProvider>    
    );
}

export default DragDropMore;