import React, {useState, useEffect} from 'react';
import {ThemeProvider, Table, TableHead, TableBody, TableCell, TableContainer, TableRow,} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";

import SklTable from '../skeletons/Skl_table';

const tableData = [
    {date:'01 July 2023', totDoc:510, modiDoc:450, unModiDoc:60, accu:'85%'},
    {date:'02 July 2023', totDoc:605, modiDoc:580, unModiDoc:25, accu:'92%'},
    {date:'04 July 2023', totDoc:880, modiDoc:860, unModiDoc:20, accu:'95%'}
]

const ReportTable = (props) => {

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([])

    useEffect(()=>{
        console.log('tableData', props.tableData)
        if(props.tableData){
            setData(props.tableData)
        }
    },[props.tableData])

    //date formatting

    const dateFormat = (inputDate) => {

        // Parse the input date
        const parsedDate = new Date(inputDate);

        // Create an array of month names
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June','July', 'August', 'September', 'October', 'November', 'December'];

        // Get the day, month, and year
        const day = parsedDate.getDate();
        const monthIndex = parsedDate.getMonth();
        const year = parsedDate.getFullYear();

        // Format the date
        const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

        return formattedDate;
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className={` relative border-solid border border-silver rounded-xl overflow-hidden`}>
                {loader ||  data.length === 0 ? (  
                    <SklTable 
                        tableRow={4}
                        tableCol={5}
                    />
                ):(
                    <TableContainer >
                        <Table stickyHeader aria-label="unknown map Datasets">  
                            <TableHead>
                                <TableRow>
                                    <TableCell className='min-w-[20rem]'>Date</TableCell>
                                    <TableCell>Tot. documents processed</TableCell>
                                    <TableCell>Modified documents</TableCell>
                                    <TableCell>Unmodified documents</TableCell>
                                    <TableCell>Accuracy %</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { data.length > 0 && data.map((item, index) => (
                                <TableRow key={index} >
                                    <TableCell className='py-4'>
                                        {item.date}
                                    </TableCell>
                                    <TableCell className='py-4'>
                                        {item.totDoc}
                                    </TableCell>
                                    <TableCell className='py-4'>
                                        {item.modiDoc}
                                    </TableCell>
                                    <TableCell className='py-4'>
                                        {item.unModiDoc}
                                    </TableCell>
                                    <TableCell className='py-4'>
                                        {item.accu}%
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </ThemeProvider>

    )
}

export default ReportTable;