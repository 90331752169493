import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";
import './Skeleton_styles.scss'

const SklInputPopup = () => {


    return(
        <ThemeProvider theme={ThemeCustom}>
            <Skeleton animation="wave" className={`input-popup`}></Skeleton>
        </ThemeProvider>
    )
}

export default SklInputPopup;