import React, {useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonOutline from '../button/Btn_outline';

import ModalStyle from './Modal_styles.module.scss';



const UploadModal = ({openUpload = false, setOpenUpload, btnEnable, modalText, loaderIcon, iconScale}) =>{

    const cancelFile = () =>{

    }

    return(
        <div>
            <Modal
                open={openUpload}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-loader']}`}> 
                    <div className={`${ModalStyle['modal-container']}  ${ModalStyle['modal-loader']}`}>
                        <div className={`${ModalStyle['content-wrapper']} ${ModalStyle['modal-loader']}`}>
                            <div className={ModalStyle['loader-content']}>
                                <div className={ModalStyle['loader-bg']}>
                                    {iconScale ? (
                                        <img src={loaderIcon} alt='loader icon' className={ModalStyle['loader-icon-scale']}/>

                                    ):(
                                        <img src={loaderIcon} alt='loader icon' className={ModalStyle['loader-icon-width']}/>

                                    )}
                                </div>
                                <Typography id="loader-modal-title" className={ModalStyle['modal-Header1']} variant="subHeader1" component="h2">
                                    {modalText}
                                </Typography>
                            </div>
                            {btnEnable &&
                                <div className={ModalStyle['loader-btn']}>
                                    <ButtonOutline btnName="Cancel" type="button" classes="mr2" onClick={() => {cancelFile(); setOpenUpload(false)}}/>
                                </div>  
                            }  
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default UploadModal;