import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';

import loadingSuccess from '../../assets/loader-new.gif'


const SentModal = ({openSent = false, setOpenSent, devMode}) =>{

    const navigate = useNavigate();

    const success = () =>{
        setOpenSent(false);
        setTimeout(()=>{
            
            if(devMode){
             navigate('/?devMode=true')
            }
            else{
                navigate('/file_upload')
            }

            sessionStorage.removeItem('invoice');
            sessionStorage.removeItem('invoiceXml');
        },1000)
    }

    return(
        <div>
            <Modal
                open={false}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-sent']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={loadingSuccess} alt='delete icon' className={ModalStyle['icon-img']}/>
                            </div>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="subHeader1" component="h2">
                                Documents sent!
                            </Typography>
                            <div className={ModalStyle['modal-sent-btn']}>
                                <ButtonSecondary btnName="Okay!" type="button" onClick={success}/>
                            </div>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default SentModal;