import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {Container, Typography} from '@mui/material';
import {ThemeProvider} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";

import InvoiceLogo from '../assets/Docupath-Logo.svg';
import loaderIcon from '../assets/Loading1.svg'

const AuthGoToBack = (props) =>{


   //has subdomain

   useEffect(()=>{

        const hostname = window.location.hostname;
        const subDomain = sessionStorage.getItem('subDomain');

        if(subDomain){
            window.location.href = `https://${subDomain}.${hostname}`;
            //alert('if',  `${subDomain}.${hostname}`)
            //sessionStorage.setItem('xxxx if', `${subDomain}.${hostname}`)
        }
        else{

            if(!hostname.includes('localhost')){
                window.location.href = `https://${hostname}`;
                //alert('else 1',  `${hostname}`)
            }
            else{
                window.location.href = `http://${hostname}:3000`;
                //alert('else 2',  `${hostname}`)
            }
            
        }

    },[])

    return(
        <ThemeProvider theme={ThemeCustom}>            
            <Container maxWidth="xl">
                <div className={`${props.class? props.class:'h-screen'} flex justify-center items-center flex-col`}>
                    <div className='flex flex-col items-center justify-center'>

                        <img className="max-w-[2.5rem] min-h-[2.5rem]" src={loaderIcon} alt="loading logo"/>
                        <Typography variant="text24" className="!font-medium mt-7" component='div'>
                            Please wait...
                        </Typography>
                        
                    </div>
                </div>
            </Container> 
        </ThemeProvider> 
    );
}

export default AuthGoToBack;