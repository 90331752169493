import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import {ThemeProvider, OutlinedInput , FormControl, InputLabel, InputAdornment, Typography } from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

import invoiceStyle from './Invoice-input_style.module.scss';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '../../assets/line-danger.svg'


const InvoiceInputMap = (props) =>{

    //const [value, setValue] = useState(props.value);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const inputChange = (e) =>{
        //console.log(e.target.value, document.getElementById(e.target.id));
        const newValue = e.target.value;
        props.onInputChange(newValue);
    }

    const handleFocus = (e) => {
        //console.log("handleFocus",e.target.value);
        props.setSearchText(e.target.value);
    }

    const handleBlur = (e) => {
        //console.log("handleFocus",e.target.value);
        props.setSearchText('');
    }
    
    return(
        <ThemeProvider theme={ThemeCustom}>
            {/* {props.compliant ? ( */}
            {true ? (
                <FormControl variant="standard" fullWidth  margin="normal" className='flex flex-row items-center my-1'>
                    <InputLabel shrink htmlFor={`id-${props.inputName}-${props.id}`} className={`w-[8rem] mb-0 mr-3 font-semibold text-xs flex items-center justify-end truncate`} title={(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}>{props.connected ? '':<img src={WarningIcon} alt='warning' className='max-w-[1rem] w-full mr-2'/>} <span className='truncate'>{(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}</span></InputLabel>
                    <OutlinedInput 
                        id={`id-${props.inputName}-${props.id}`}
                        autoComplete="off" 
                        type={props.inputType}
                        value={props.value === null ? '':props.value}
                        readOnly={props.readonly}
                        className={`max-h-[2rem] rounded-md ${invoiceStyle['invoice-input']} ${props.connected ? '': `${invoiceStyle['invoice-map']}`}  ${props.readonly ? "opacity-50	":""}`}
                        sx={{'&.Mui-focused .MuiOutlinedInput-notchedOutline':{border:'1px solid #FFF4EB'}}}
                        {...register(`input-${props.inputName}`,{onChange: (e) => {inputChange(e)}} )} 
                        onFocus={(e)=>handleFocus(e)}
                        onBlur={(e)=>handleBlur(e)}
                        onKeyDown={(e)=> e.preventDefault()}
                        onContextMenu={(e)=> e.preventDefault()}
                    />
                </FormControl>
            ):(
                <FormControl variant="standard" fullWidth  margin="normal" className='flex flex-row items-center my-1'>
                    <InputLabel shrink htmlFor={`id-${props.inputName}-${props.id}`} className={`w-[8rem] mb-0 mr-3 text-right font-semibold text-xs`} title={(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}>{(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}</InputLabel>
                    <OutlinedInput 
                        id={`id-${props.inputName}-${props.id}`}
                        autoComplete="off" 
                        type={props.inputType}
                        value={props.value}
                        className={`max-h-[2rem] cursor-pointer rounded-md ${invoiceStyle['invoice-input']} ${props.readonly ? "opacity-50	":""}`}
                        {...register(`input-${props.inputName}`,{onChange: (e) => {inputChange(e)}})} 
                        readOnly={props.readonly}
                        onFocus={(e)=>handleFocus(e)}
                        onBlur={(e)=>handleBlur(e)}
                        onKeyDown={(e)=> e.preventDefault()}
                        onContextMenu={(e)=> e.preventDefault()}
                    />
                </FormControl>
            )}
        </ThemeProvider>
    )
}

export default InvoiceInputMap;