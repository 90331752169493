import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";
import loaderIcon from '../../assets/Loading1-light.svg';

const ButtonSecondaryLoader = (props) => {
    return (
        <ThemeProvider theme={ThemeCustom}>
            <Button variant={props.variant} type={props.type} className={`${props.classes} flex items-center btn-two-state`} onClick={props.onClick} disabled={props.disabled}>
                {props.btnName} { props.btnLoading ? (<img src={loaderIcon} alt='loader' className='w-5 h-5 ml-2 btn-process-img-popup'/>) : ('') } 
            </Button>
        </ThemeProvider>
    );
}

export default ButtonSecondaryLoader;