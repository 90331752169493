import React, {useState, useEffect, useRef, useCallback} from 'react';
import SimpleBar from 'simplebar-react';
import useWebSocket, {ReadyState } from 'react-use-websocket';
import { useInView } from 'react-intersection-observer'
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../../context/User_Access';
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import { useQuery, useQueryClient, useInfiniteQuery} from '@tanstack/react-query';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, Skeleton, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, IconButton, AppBar, Container, Checkbox, Tabs, Tab, Tooltip, Typography } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

import ButtonSecondary from '../button/Btn_secondary';
import NotFound from '../masseges/Not_found';
import Loading from '../masseges/Loading';
import Status from './status';
import DeleteModal from '../modal/Modal_delete';
import ButtonOutlineImg from '../button/Btn_outline_img';
import DeleteWarnModal from '../modal/Delete_warning';
import SklTab from '../skeletons/Skl_tabs';
import SklTable from '../skeletons/Skl_table';

import ExportIcon from '../../assets/download.svg'
import DeleteIcon from '../../assets/delete.svg'
import download from '../../assets/download.svg'
import trash from '../../assets/trash-red.svg'
import CheckIcon from '../../assets/tick-select.svg'; 
import UncheckIcon from '../../assets/tick-notselect.svg'; 
import EyeIcon from '../../assets/eye.svg'; 
import Rolling from '../../assets/Rolling.svg'
import infoCircleDark from '../../assets/info-circle-dark.svg'
import columnFilter from '../../assets/column-filter.svg'
import ReTry from '../../assets/retry.svg'
import SortIcon from '../../assets/arrow-head-down.svg'
import FlashIcon from '../../assets/flash.svg'
import loaderIcon from '../../assets/Loading1.svg'

//column resize

const MIN_WIDTH = 200; 

//websocket 

const getListWebSocketUrl = 'wss://b3zk8ul1y4.execute-api.eu-central-1.amazonaws.com/production/';
const getListWebSocketUrlStaging = 'wss://b3zk8ul1y4.execute-api.eu-central-1.amazonaws.com/staging/';

const websocketConfig = {
    onOpen: () => console.log('opened WS')
}

const 
DocSummary = (props) => {
    //const [docs, setDocs] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedDelete, setSelectedDelete] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [delType, setDelType] = useState(null);
    const [modalText, setModalText]  = useState('');
    const [value, setValue] = useState(0);
    const [selectedId, setSelectedId] = useState([]);
    const [tabState, setTabState] = useState('processing,retrying,pending_review,failed');
    const [searchTerm, setSearchTerm] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [loader, setLoader] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [processDoc, setProcessDoc] = useState(false);
    const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
    //const [maxRowCount, setMaxRowCount] = useState(500);
    const [rowCount, setRowCount] = useState(12);
    const [sort, setSort] = useState(0)
    const {BaseUrl} = useBaseUrlContext()
    const {devMode, userName} = useUserContext()
    const {tokenReview, popupAction} = useAuthContext()
    const queryClient = useQueryClient(); 
    const scrollableNodeRef = useRef();
    const navigate = useNavigate()
    const location = useLocation();
    const { ref, inView } = useInView()
    const fileNameRef = useRef(null);
    const docNoRef = useRef(null);
    const supplierRef = useRef(null);
    const isResizing = useRef(null);
    const [fileNameCellWidth, setFileNameCellWidth] = useState('20vw')
    const [docNoCellWidth, setDocNoCellWidth] = useState('9rem')
    const [supplierCellWidth, setSupplierCellWidth] = useState('12vw')
    const [socketUrl, setSocketUrl] = useState(null);
    const {sendJsonMessage, lastJsonMessage, readyState, getWebSocket} = useWebSocket(socketUrl, websocketConfig);
    const [requestTime, setRequestTime] = useState(0)
    const [wsResponse, setWsResponse] = useState(false)
    const axiosInstance = useAxiosInstance();

    //select relavant socket

    useEffect(()=>{
        const webSocketURL = (BaseUrl.includes('docupathdev') ||  BaseUrl.includes('localhost') || BaseUrl.includes('invoiceai.local')) ? getListWebSocketUrlStaging : getListWebSocketUrl;
        setSocketUrl(webSocketURL);
    },[])

    useEffect(()=>{
        console.error('cache data XXX loader', loader)
    },[loader])

    //websocket init request *****

    useEffect(() => {
        console.log("*************Connection state changed*************", readyState, getWebSocket())
        if (readyState === ReadyState.OPEN) {
          sendJsonMessage({
            route: "saveConnection",
            accessToken: `${Cookies.get('token')}`
          })
        }
    }, [readyState])

    //websocket responses *****

    useEffect(() => {

        if(lastJsonMessage){

            console.log(`*************Got a new message:*************`, lastJsonMessage)
            setWsResponse(true)

        }

    }, [lastJsonMessage])

    //call refetchQuery *****

    useEffect(()=>{

        console.log('*************wsResponse*************',wsResponse)

        if(wsResponse){
            refetchQuery()
        }

    },[wsResponse])


    //set refetch intervals *****

    const refetchQuery = () => {

        let timeDiff = Math.trunc(new Date().getTime()/1000) - requestTime;

        console.log('*************trigger refetch*************', requestTime, 'timeDiff', timeDiff)
        
        if (timeDiff < 1){

            console.log('*************trigger refetch if*************')
            refetch()

        }
        else{

            console.log('*************trigger refetch else*************')
            setTimeout(()=>{

                refetch()
                console.log('*************trigger refetch else inside time out*************')

            },timeDiff*1000)
        }

    }

    //list file fetch *****

    const fetchData = async ({ queryKey, pageParam }) => {

        try {

            const [_key, rowCount, filter, region, sort, tabState, searchTerm] = queryKey;
    
            console.log('fetchData inside---', {queryKey, pageParam});

                setWsResponse(false)
                setRequestTime(Math.trunc(new Date().getTime()/1000))

                if(region !== null) {

                    console.log('region inside', region)

                    // Await the axios call to ensure you receive the response before proceeding
                    const res = await axiosInstance.get(`/list_files.php?recordsCount=${rowCount}&fileType=${filter}&status=${tabState}&search=${searchTerm}&regions=${region}&sort=${sort}&autoIncrement=${pageParam}`);
                    
                    let data = res.data.result;
                    let error = res.data.error;

                    console.log('fetch response', data.documents, data)
                    return data;

                }

        } catch (err) {

            console.error('fetchData error:', err.message);
            throw err;

        }
        
    };

    //useQuery hook with queryKey array including all dependencies *****

    const { isLoading, data:docs, fetchNextPage, hasNextPage, isFetchingNextPage,  refetch} = useInfiniteQuery({

        queryKey: ['fetchData', rowCount, props.filter, props.regionFilterSting, sort, tabState, searchTerm],
        queryFn: fetchData,
        cacheTime: 24 * 60 * 60 * 1000, // Cache time set to 24h
        refetchInterval: 5000,
        initialPageParam:0,
        getNextPageParam: (lastPage, allPages) => {
            if(lastPage){
                console.log('lastPagexxx', lastPage.autoIncrement ? lastPage.autoIncrement : 0)
                return lastPage.autoIncrement;
            }
        }    

    });

    //get data as pages *****

    useEffect(() => {
        console.log('nextPage', inView)
        if (inView) {
            console.log('nextPage inView')
          fetchNextPage();
        }
    }, [fetchNextPage, inView]);

    // Manually trigger fetchData *****

    const handleFetchData = async () => {
        refetch()
        console.log('refetch')
        //queryClient.invalidateQueries(['fetchData', rowCount, props.filter, props.regionFilterSting, sort, tabState, searchTerm]);
    };

    //remove data from cache *****

    const removeItemFromCache = (delId) => {

        console.log('removeItemFromCache call')
        //console.clear()

        const queryKey = ['fetchData', rowCount, props.filter, props.regionFilterSting, sort, tabState, searchTerm]; // Use the same query key as your infinite query
      
        // Access the current cache data for the query
        const currentData = queryClient.getQueryData(queryKey);
        console.log('removeItemFromCache', delId, currentData)

        if (!currentData) {
            console.log('No data in cache for this queryKey:', queryKey);
            return; // Exit if there's no data
        }

        if (currentData) {
            console.log('removeItemFromCache currentData', currentData)
          // Clone the current data to avoid directly mutating the cache
            const updatedData = {
                ...currentData,
                pages: currentData.pages.map(page => {
                    console.log('currentData.pages', page,page.documents.filter(item => item.id !== delId))
                    // Assuming each page is an array of items, filter out the item to remove
                    const updatedDocuments = page.documents.filter(item => item.id !== delId);
                    return {
                        ...page,
                        documents: updatedDocuments,
                    };
                }),
            };

          console.log('removeItemFromCache updatedData', updatedData)

          // Update the cache with the modified data
          queryClient.setQueryData(queryKey, updatedData);
        }
      }

    //handling data preview in table *****

    useEffect(() => {

        console.log('docs********', docs,'docs.page', docs ? docs.pages : 'no', 'docs.pages at', docs ? docs.pages.at(0) : 'no at')

        //set data not found

        if(docs && docs.pages.at(0)){

            if(docs.pages.at(0) && docs.pages.at(0).documents.length < 1){
                setNotFound(true)
                console.log('not found if', docs.pages && docs.pages.at(0).documents.length);
            }
            else{
                setNotFound(false)
                //console.log('not found else');
            }

            //set max row count

            //docs && docs.document.length > maxRowCount ? setMaxRowCount(docs.document.length -1) : setMaxRowCount(maxRowCount)

        }
      
    }, [docs]); 

    //set loaders

    useEffect(()=>{

        if (isLoading) {
            console.log('isLoading', isLoading);
            setLoader(true);
        }
        else{
            setLoader(false);
            console.log('isLoading else', isLoading);
        }

    },[docs, isLoading])

    //set the search word  *****

    useEffect(()=>{

        //console.log('searchTerm child', props.searchTerm)
        const delayDebounce = setTimeout(() => {
            console.log('textsearch',props.searchTerm)
            setSearchTerm(props.searchTerm)
            //setLoader(true);
        }, 300);
      
        console.log('textsearch before ret',props.searchTerm)
        return () => clearTimeout(delayDebounce);

    },[props.searchTerm])

    //skip the processing documents from select all *****

    useEffect(()=>{
        //console.log('docs',docs);

        if(docs && docs.pages.at(0)){

            if(docs.pages.at(0) && docs.pages.at(0).documents.length > 0){

                let processDoc = docs && docs.pages.map((page, i) => {
                    return page && page.documents.filter(doc => doc.status === "processing");
                }).reduce((acc, val) => acc.concat(val), []); // Flatten the array of arrays

                console.log('process doc test-xxx', processDoc)

                if(processDoc.length > 0){
                    setAllSelected(false);
                    setProcessDoc(true)
                }
                else{
                    setProcessDoc(false)
                }

            }

        }

    },[docs])

    //file select handling *****

    const isItemSelected = (checked, id, status) => {

        console.log('isItemSelected-----------------------', checked, typeof(id),status)

        if(checked){

            if(status !== 'failed'){
                setSelected([...selected, id])
                //console.log('status checj sel-----------------------', id,status)
            }
            setSelectedDelete([...selectedDelete, id])
        
        }
        else{

            let docUncheck = selected.filter((select) => select !== id);
            let docUncheckDel = selectedDelete.filter((select) => select !== id);
            setSelected(docUncheck)
            setSelectedDelete(docUncheckDel)

        }
    }

    //all select relates functionalities *****

    useEffect(()=>{

        console.log('sel arr',selected, 'sel arr del', selectedDelete);
        
        if(selected.length > 0){

            if(docs && docs.pages.at(0)){

                const SelectedDoc = docs && docs.pages.map((page, i) => {

                    return page && page.documents.map((doc, index) => {
                        console.log('SelectedDoc', doc.status.value)
                        if(doc.status.value === "pending_review"  ||  doc.status.value === "no_org" || doc.status.value === "rejected" ||  doc.status.value === "approved_sent"){
                            return doc.id;
                        }    
                        return null; // Return null for documents that don't match the condition
                    }).filter(id => id !== null); // Filter out null values

                }).reduce((acc, val) => acc.concat(val), []); // Flatten the array of arrays

                const allSelectedwFail = docs && docs.pages.map((page, i) => {

                    return page && page.documents.map((doc, index) => {
                        console.log('isItemAllSelected', doc.status.value)
                        if(doc.status.value === "pending_review" || doc.status.value === "no_org" ||  doc.status.value === "rejected" ||  doc.status.value === "approved_sent" ||  doc.status.value === 'failed'){
                            return doc.id;
                        }
                        return null; // Return null for documents that don't match the condition
                    }).filter(id => id !== null); // Filter out null values

                }).reduce((acc, val) => acc.concat(val), []); // Flatten the array of arrays

                //console.clear()
                allSelectedwFail.length === selectedDelete.length ? setAllSelected(true) : setAllSelected(false);
                console.log('test-xxx SelectedDoc', SelectedDoc.length,'selected', 'selectedDelete',selectedDelete.length, selected.length, 'allSelectedwFail', allSelectedwFail)

            }

        } 
        else{
            setAllSelected(false)
        } 
        
    },[selected, selectedDelete])

    //all item select - checkbox *****
 
    const isItemAllSelected = (checked) => {

        console.log('isItemSelected All', checked)

        if(checked){

            if(docs && docs.pages.at(0)){
            
                const allSelected = docs && docs.pages.map((page, i) => {

                    return page && page.documents.map((doc, index) => {

                    console.log('isItemAllSelected', doc.status.value)
                    if(doc.status.value === "pending_review" ||  doc.status.value === "no_org" || doc.status.value === "rejected" ||  doc.status.value === "approved_sent"){
                        return doc.id;
                    }   
                    return null; // Return null for documents that don't match the condition
                    }).filter(id => id !== null); // Filter out null values

                }).reduce((acc, val) => acc.concat(val), []); // Flatten the array of arrays

                const allSelectedwFail = docs && docs.pages.map((page, i) => {

                    return page && page.documents.map((doc, index) => {

                    console.log('isItemAllSelected', doc.status.value)
                    if(doc.status.value === "pending_review" ||  doc.status.value === "no_org" || doc.status.value === "rejected" ||  doc.status.value === "approved_sent" ||  doc.status.value === 'failed'){
                        return doc.id;
                    }    
                    return null; // Return null for documents that don't match the condition
                    }).filter(id => id !== null); // Filter out null values
                
                }).reduce((acc, val) => acc.concat(val), []); // Flatten the array of arrays

                console.log('text-xxx 2 allSelected',allSelected, 'fail', allSelectedwFail)
                setSelected(allSelected);
                setSelectedDelete(allSelectedwFail)
                setAllSelected(true);

            }

        }
        else{
            setSelected([]);
            setSelectedDelete([])
            setAllSelected(false);
        }

    }

    //selected ids save in session storage

    useEffect(()=>{

        //console.clear()
        console.log('selected xxx',selected)
        sessionStorage.setItem('selectedDoc', JSON.stringify(selected))

    },[selected])

    //remove selcted checkboxes when tabs and filters change *****

    useEffect(()=>{

        setSelected([]);
        setSelectedDelete([])
        setAllSelected(false);
        handleFetchData()
         
    },[props.filter, searchTerm, tabState, props.regionFilterSting, props.regionFilterImport, sort])

    //if uploading files this will be apply *****

    useEffect(()=>{
        console.log('uploading ************', props.uploading);
        if(props.uploading === false){
            if(docs && docs.pages.at(0)){
                if(docs.pages.at(0) && docs.pages.at(0).documents.length > 0){
                    //fetchData(rowcount, props.filter, props.regionFilterSting, props.regionFilterImport, sort);
                    handleFetchData()
                    console.log('uploading ************ if');
                }
                else{
                    setLoader(true);
                    //fetchData(rowcount, props.filter, props.regionFilterSting, props.regionFilterImport, sort);
                    handleFetchData()
                    console.log('uploading ************ else');
                }
            }
        }
    },[props.uploading])    
     

    //multiple files delete *****

    const deleteMutiple = (id) => {
        console.log('deleteMutiple', selected)
        setModalText(selectedDelete.length);
        if(selected.length > 100){
            setOpenDeleteWarning(true)
        }
        else{
            setOpenDelete(true);
        }
        setDelType(2)
    }

    //single file delete *****

    const deleteFile = (id, name) => {
        console.log('deleteFile', id, name, docs)
        setModalText(name);
        setOpenDelete(true);
        setDelType(1);
        setSelectedId(id);
    }

    // delete relates function *****

    const deleteDataRow = () => {

        console.log('row', selectedId)
        if(docs && docs.pages.at(0)){
            if(docs.pages.at(0) && docs.pages.at(0).documents.length > 0){
                //let rowcount = maxRowCount;
                setLoader(true)
                //fetchData(rowcount, props.filter, props.regionFilterSting, props.regionFilterImport, sort);
                handleFetchData()
                
                setDeleting(false);
                setSelected([]);
                setSelectedDelete([])
                setAllSelected(false);
            }
        }
        //let delDoc = docs && docs.document.filter(doc => doc.id !== selectedId);
        //setDocs(delDoc);
        console.log('delete row', docs && docs.document )
        
    }

    //singe file review *****

    const singleReviewFile = async (id) => {

        sessionStorage.setItem('selectedDoc', JSON.stringify([id]))
        sessionStorage.setItem('reviewPageIndex', 0)

        if(devMode){
            navigate('/approve?devMode=true');
        }else{
            navigate('/approve');
        }
     
    }

    //multiple file review *****

    const multipleReview = async () => {

        //const firstDoc = selected.at(0)
        sessionStorage.setItem('reviewPageIndex', 0)

        if(devMode){
            navigate('/approve?devMode=true');
        }else{
            navigate('/approve');
        }
 
    }

    //tab change *****

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };  

    //pendding tab click *****

    const pendingTabClick = () => {
        console.log('pendingTabClick')
        setTabState('processing,retrying,pending_review,failed');
        setSearchTerm('');
        props.setSearchTerm('');
    }

    //approve tab click *****

    const approvedTabClick = () => {
        console.log('approvedTabClick')
        setTabState('approved_sent');
        setSearchTerm('');
        props.setSearchTerm('');
    }

    //reject tab click *****

    const rejectTabClick = () => {
        console.log('rejectTabClick');
        setTabState('rejected');
        setSearchTerm('');
        props.setSearchTerm('');
    }

    //singe file download *****

    const downloadFile = async (id, downloadName) => {

        try {

            const res = await axiosInstance.get(`/download.php?type=document&ids=${id}`, {
                responseType: 'blob',
            })
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', downloadName); 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

        } catch (err) {
            console.error('fetchData:', err);
            props.setOpenFail(true)
            props.setTextFail('The server encounted an error and could not complete your request.')
        }  


    }

    //mutliple file download *****

    const multipleDownload = async () => {

        console.log('multipleDownload selected', selected, 'selected delete', selectedDelete, 'docs', docs)

        if(selectedDelete.length === 1){

            // const selId = selectedDelete.at(0)
            // const arrayFIlter = docs?.pages && docs.pages.map((item, index)=>{
            //     console.log('item ', item.documents.filter(doc => console.log('doc',  doc.id === selId)))
            //     return item.documents.filter(doc => doc.id === selId)
            // })
            // let docName = arrayFIlter.filter(subArray => subArray.length > 0).at(0).at(0).downloadName;
            // console.log('docName', docName, arrayFIlter)
            // downloadFile(selId, docName)

            const selId = selectedDelete.at(0);
            const docToDownload = docs?.pages?.flatMap(page => 
            page.documents.filter(doc => doc.id === selId)
            ).find(doc => doc);
            if (docToDownload) {
                console.log('Document found:', docToDownload, docToDownload.downloadName);
                downloadFile(selId, docToDownload.downloadName);
            } else {
                console.log('Document not found');
            }

        }
        else{

            console.log('multipleDownload else xxx')

            try {

                const res = await axiosInstance.get(`/download.php?type=document&ids=${selectedDelete}`, {
                    responseType: 'blob',
                })

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${selectedDelete.length}-files.zip`); 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } catch (err) {
                console.error('fetchData:', err);
            }  

        }

    }

    // Function to convert a timestamp to a human-readable date *****

    const timestampToHumanDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert to milliseconds
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    //add columns filter *****

    const addColums = () => {
        //console.log('addColums---',addColums)
        props.setOpenAddColumn(true)
    }

    //file retrying *****

    const retryFile = async (id) => {

        console.log('retryFile')

        try {

            let documentId = {
                "documentId":id
            }    

            const res = await axiosInstance.post(`/reprocess_file.php`, documentId)

            console.log(res.data.result)
            props.setOpenSuccess(true)
            props.setTextSuccess('Document processing starting soon.')
    
        } catch (err) {
            console.error('fetchData:', err);
        }  
        
    }

    //not allow to drag and drop the files to the doc review page *****

    const preventDefaultBehavior = (e) => {
        e.preventDefault();
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
    };

    //not allow to drag and drop the files to the doc review page *****

    useEffect(() => {
        const handleDragStart = preventDefaultBehavior;
        const handleDragOver = preventDefaultBehavior;
        const handleDrop = preventDefaultBehavior;

        window.addEventListener('dragstart', handleDragStart, false);
        window.addEventListener('dragover', handleDragOver, false);
        window.addEventListener('drop', handleDrop, false);

        return () => {
        window.removeEventListener('dragstart', handleDragStart, false);
        window.removeEventListener('dragover', handleDragOver, false);
        window.removeEventListener('drop', handleDrop, false);
        };
    }, []);
    
    //will be removed start

    useEffect(()=>{
        //console.log('xxxxuploadFiles',props.uploadFiles);
        //console.log('xxxsubmitFiles',props.submitFiles);
    },[props.uploadFiles, props.submitFiles])

    //useEffect(()=>{console.log('sort---', sort)},[sort])

    //useEffect(()=>{console.log('selected---', selected)},[selected])

    //useEffect(()=>{console.log('regionFilterSting chile el',props.regionFilterSting)},[props.regionFilterSting])

    useEffect(()=>{
       //console.log('allSelected',allSelected)
    },[allSelected])

    //useEffect(()=>{console.log('loader---', loader)},[loader])

    //useEffect(()=>{console.log('props.regionFilterImport',props.regionFilterImport)},[props.regionFilterImport])

    useEffect(()=>{
        console.log('docsxxx 1','docs')
        if( docs && docs.pages.at(0)){
            console.log('docsxxx','docs', '2', docs.pages.at(0).documents )
        }
    },[docs])

    //will be removed end

    //resize
    
    const startResizing = (ref, columnId) => {
        console.log('startResizing',  ref)
        isResizing.current = { ref, columnId };
        document.body.style.cursor = "col-resize";
    };
    
    const handleOnMouseMove = (e) => {
        //console.log('isResizing.current', isResizing.current, ref)
        if (isResizing.current) {
          const newWidth = e.clientX - isResizing.current.ref.getBoundingClientRect().left;
          isResizing.current.ref.style.maxWidth = Math.max(newWidth, MIN_WIDTH) + "px";
          console.log("Resizing column:", isResizing.current.columnId); // Log which column is being resized

          if(isResizing.current.columnId === 'fileName'){
            setFileNameCellWidth(Math.max(newWidth, MIN_WIDTH))
          }
          else if(isResizing.current.columnId === 'docNo') {
            setDocNoCellWidth(Math.max(newWidth, MIN_WIDTH))
          }
          else if(isResizing.current.columnId === 'supplier') {
            setSupplierCellWidth(Math.max(newWidth, MIN_WIDTH))
          }
        
        }
    };
    
    const handleOnMouseUp = () => {
        isResizing.current = null;
        document.body.style.cursor = "auto";
    };
    
    useEffect(() => {
        document.addEventListener('mousemove', handleOnMouseMove);
        document.addEventListener('mouseup', handleOnMouseUp);
        return () => {
          document.removeEventListener('mousemove', handleOnMouseMove);
          document.removeEventListener('mouseup', handleOnMouseUp);
        };
    }, []);

    useEffect(()=>{
        //alert('loader', JSON.stringify(loader) )
    },[loader])

    
    return(
        <ThemeProvider theme={ThemeCustom}>
            <DeleteWarnModal
                setOpenDeleteWarning={setOpenDeleteWarning}
                openDeleteWarning={openDeleteWarning}
            />
            <DeleteModal 
                setOpenDelete={setOpenDelete} 
                setDeleting={setDeleting} 
                deleteDataRow={deleteDataRow} 
                openDelete={openDelete} 
                delType={delType} 
                selectedId={selectedId} 
                selected={selected} 
                modalText={modalText} 
                selectedDelete={selectedDelete}
                from={'docReview'} 
                tokenReview={props.tokenReview}
                removeItemFromCache={removeItemFromCache}
            />
            <div className='flex justify-between items-center document-review'>
                <div className='flex items-center'>
                    {props.sklCommon ? (
                        <SklTab/>
                    ):(
                        <Tabs value={value} onChange={handleChange} className='border-b-0' aria-label="document review tab">
                            <Tab label="Pending" onClick={pendingTabClick} className='rounded-t-xl' disableRipple />
                            <Tab onClick={approvedTabClick} label="Approved" className='rounded-t-xl'  disableRipple/>
                            <Tab label="Rejected" onClick={rejectTabClick} className='rounded-t-xl' disableRipple/>
                        </Tabs>
                    )
                    }
                    <div>
                        {devMode?(
                            <IconButton  onClick={addColums} disableRipple={true} className='p-5'>
                                <img src={columnFilter} alt='icon' className='w-6'/>
                            </IconButton>
                        ):''
                        }
                    </div>
                    {/* <div className='ml-4'>
                        <Typography variant="text18" className="font-medium  hidden md:block" component='div'>
                            73 of {docs && docs.pages.at(0).documentsCount} documents
                        </Typography>
                        <Typography variant="textBase" className="font-medium md:hidden" component='div'>
                            73 of 548 docs
                        </Typography>
                    </div> */}
                </div>
                <div className='flex items-center justify-between'>
                    {/*props.uploading ? (
                        <div className='flex items-center font-sans text-xl font-medium text-primary me-10'>
                            <img src={Rolling} alt='loading' className='max-w-[1.75rem] mr-2'/>
                            Upload in progress
                        </div>
                    ):''
                    */}
                    {props.sklRegion ? (
                        <Skeleton animation="wave" className='w-[31.5rem] max-h-[1.25rem] h-[1.25rem] rounded' ></Skeleton>
                    ):(
                    <Tooltip title={props.allselFilterName} arrow placement="bottom" className='hidden lg:inline'>
                        <div className={`mr-4 ${deleting ? 'max-w-[20vw]':'max-w-[35vw]'} truncate text-sm text-sonicSilver font-medium cursor-default`}>
                            Filters: 
                            <span className='text-primary ml-2'>{`${props.allselFilterName}`}</span>
                        </div>
                    </Tooltip>   
                    )} 
                    {deleting ? (
                        <div className='flex items-center font-sans text-xl font-medium text-primary me-10'>
                            <img src={Rolling} alt='loading' className='max-w-[1.75rem] mr-2'/>
                            Delete in progress
                        </div>
                    ):''
                    }
                </div>
            </div>
            <div className={`mb-5 border-solid border border-borderGrayDark rounded-tl-none rounded-xl doc-review overflow-hidden relative ${selectedDelete.length > 0 ? 'min-height-docView' : 'min-height-docView-uncheck'}`}>
                {/* <SimpleBar className={selectedDelete.length > 0 ? 'min-height-docView' : 'min-height-docView-uncheck'} scrollableNodeProps={{ ref: scrollableNodeRef }} > */}
                <div>
                {/* {loader || (props.uploading && (docs?.pages?.at(0)?.documents.length < 1)) ? (   */}

                {loader || (props.uploading && (docs?.pages?.at(0)?.documents.length < 1)) ? (

                    <SklTable 
                        tableRow={10}
                        tableCol={5}
                        hasCheckBox={true}
                        hasActions={5}
                    />
                    
                    ): notFound && !props.uploading ? (  
                    
                        <NotFound msgText={'No documents found!'}/>

                    ):(
                        
                    <TableContainer className={selectedDelete.length > 0 ? 'height-docView' : 'height-docView-uncheck'}>
                        <Table stickyHeader aria-label="Review documents" className='doc-review-table w-full'>  
                            <TableHead className='tableHead'>
                                <TableRow>
                                    <TableCell className='w-6 rounded-none'>
                                        <Checkbox 
                                            disableTouchRipple={true}
                                            disableRipple={true}
                                            className={`${processDoc ? 'opacity-20':''} py-0`}
                                            color="primary"
                                            id='selAll'
                                            checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                            icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                            checked={allSelected}
                                            onChange={(e)=>isItemAllSelected(e.target.checked)}
                                            disabled={processDoc}
                                        />
                                    </TableCell>
                                    <TableCell className='tableCell resizable' ref={fileNameRef} >
                                        File Names
                                        <div onMouseDown={() => startResizing(fileNameRef.current, 'fileName')} className="resizeLine" />
                                    </TableCell>
                                    <TableCell className='tableCell resizable' ref={docNoRef}>
                                        Document No.
                                        <div onMouseDown={() => startResizing(docNoRef.current, 'docNo')} className="resizeLine" />
                                    </TableCell>
                                    <TableCell>
                                        <div  onClick={()=>{sort === 0 ? setSort(1) : setSort(0)}} className='cursor-pointer flex items-center'>
                                            Upload Time
                                            <img src={SortIcon} alt='icon' className={`${sort !== 0 ?'rotate-180 w-5':'w-5'} ml-4`}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell className='tableCell resizable' ref={supplierRef}>
                                        Supplier
                                        <div onMouseDown={() => startResizing(supplierRef.current, 'supplier')} className="resizeLine" />
                                    </TableCell>
                                    <TableCell>Status</TableCell>
                                    { devMode?(
                                    <TableCell></TableCell>
                                    ):''}
                                    <TableCell className='rounded-tr-xl'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { docs && docs.pages.map((page, index) => (
                                <React.Fragment key={`${page?.autoIncrement}-${index}`}>
                                {page && page.documents.map((doc, index)=>(
                                <TableRow key={doc.id} >
                                    <TableCell className={` min-w-[4.5rem] min-h-[4rem]`}>
                                       
                                        <Checkbox 
                                            disableTouchRipple={true}
                                            className={`${doc.status.value === "processing" || doc.status.value === "retrying" ? 'opacity-20':''} hover:bg-transparent`}
                                            color="primary"
                                            id={`sel-${doc.id}`}
                                            checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                            icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                            checked={selectedDelete.includes(doc.id)}
                                            onChange={(e)=>isItemSelected(e.target.checked, doc.id, doc.status.value)}
                                            disabled={ doc.status.value === "processing" || doc.status.value === "retrying" ? true : false}
                                        />
                                    
                                    </TableCell>
                                    <TableCell className={`truncate min-w-[14rem] min-h-[4rem]`} sx={{maxWidth:fileNameCellWidth}}>
                                        
                                        {doc.fileName ? doc.fileName : 'N/A'}
                                    
                                    </TableCell>
                                    <TableCell className={`truncate min-w-[12rem] min-h-[4rem]`} sx={{maxWidth:docNoCellWidth}}>
                                  
                                        {doc.invoiceNumber ? doc.invoiceNumber  : 'N/A'}
                                    
                                    </TableCell>
                                    <TableCell className='min-w-[10rem] min-h-[4rem] truncate'>
                                 
                                        {doc.createdTimestamp ? timestampToHumanDate(doc.createdTimestamp)  : 'N/A'}
                                    
                                    </TableCell>
                                    <TableCell className={`truncate max-w-[10rem] min-w-[10rem] min-h-[4rem]`} >
                                  
                                        {doc.documentType ? doc.documentType : 'N/A'}
                                    
                                    </TableCell>
                                    <TableCell className='truncate min-w-[4rem] min-h-[4rem]' sx={{maxWidth:supplierCellWidth}}>
                                       
                                        {doc.supplier ? doc.supplier : 'N/A'} 
                                    
                                    </TableCell>
                                    <TableCell className={`${ doc.status == 'not_acknowledge' || doc.status == 'acknowledged' ? 'min-w-[16rem]':'min-w-[12rem]' } min-h-[4rem]`}>
                                    
                                        <Status type={doc.status} />
                               
                                    {/* classes={selected.includes(doc.id)? 'bg-white': 'bg-cultured'} */}
                                    </TableCell>
                                    {devMode?(
                                    <TableCell >
                                    
                                        <ButtonOutlineImg btnName="Auto Process" variant={'primaryOutline'} img={FlashIcon} type="button" classes='py-2 auto-procees-btn min-h-[auto] rounded-lg'  onClick={()=>{}} disabled={false}/>
                                    
                                    </TableCell>
                                    ):''}
                                    <TableCell className={`text-center min-w-[20rem] lg:max-w-[20rem]`}>
                                    
                                        <div className='flex items-center min-h-[2.5rem] border-l border-solid border-borderGrayDark border-0'>
                                            <IconButton 
                                                onClick={()=>singleReviewFile(doc.id)} 
                                                className={`${doc.status.value === "processing" || doc.status.value === "retrying" || doc.status.value === "failed" ? 'opacity-30':''} mx-6`}
                                                disabled={ doc.status.value === "processing" || doc.status.value === "retrying" || doc.status.value === "failed" ? true : false}
                                            >
                                                <img src={EyeIcon} alt='icon' className='w-5'/>
                                            </IconButton>
                                            <Tooltip 
                                                title={
                                                <div className='tooltip-row'>
                                                    <div className='info-row'>
                                                        <div className='title'>{doc.emailFrom === null ? 'Origin user:' : 'Origin email:'}</div>
                                                        <div className='detail'>{doc.emailFrom === null ? doc.uploaderName ? doc.uploaderName : 'Uploaded via API':doc.emailFrom}</div>
                                                    </div>
                                                </div>} 
                                                arrow 
                                                placement="top"
                                                classes={{ tooltip: 'action-info' }}
                                            >
                                                <IconButton className=' mr-4'>
                                                    <img src={infoCircleDark} alt="info" className="w-5" />
                                                </IconButton>
                                            </Tooltip>
                                            <IconButton  onClick={()=>downloadFile(doc.id, doc.downloadName)} className='mr-4'>
                                                <img src={ExportIcon} alt='icon' className='w-5'/>
                                            </IconButton>
                                            <IconButton  
                                                onClick={()=>retryFile(doc.id)} 
                                                className={`${tabState === "approved_sent" || tabState === "rejected" ? 'opacity-30':''} mr-4`}
                                                disabled={tabState === "approved_sent" || tabState === "rejected" ? true : false}
                                            >
                                                <img src={ReTry} alt='icon' className='w-5'/>
                                            </IconButton>
                                            <IconButton  onClick={()=>deleteFile(doc.id, doc.fileName)} className='mr-4'>
                                                <img src={DeleteIcon} alt='icon' className='w-5'/>
                                            </IconButton>
                                        </div>
                                    
                                    </TableCell>
                                </TableRow>
                                ))}
                                </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                        <div ref={ref} className='h-[0.25rem] w-full text-center'>
                            {isFetchingNextPage? 
                                <>
                                    <img className="max-w-[2rem] min-h-[2rem] py-4" src={loaderIcon} alt="loading logo"/>
                                </>
                            :hasNextPage?
                                ''    
                            :                         
                                ''                   
                            }                
                        </div>
                    </TableContainer>
                )}
                </div>
                {/* </SimpleBar> */}
                {props.uploading ? (
                    !props.uploadCompeted ? (
                        <div className='absolute text-primary text-base bottom-0 right-0 flex bg-white border border-solid border-primary px-8 py-4 rounded-xl m-5 font-semibold'><img src={Rolling} alt='loading' className='w-5 mr-3'/>Uploaded {props.uploadFiles} of {props.submitFiles} items...</div>
                        ):(
                            <div className='absolute text-primary text-base bottom-0 right-0 flex bg-white border border-solid border-primary px-8 py-4 rounded-xl m-5 font-semibold'><img src={Rolling} alt='loading' className='w-5 mr-3'/>{props.uploadFiles} of {props.submitFiles} uploads completed. Please wait...</div>
                        )
                    ):''
                }
            </div>
            {selectedDelete.length > 0  ? (
            <AppBar position="fixed" className='bg-white shadow-none border-t border-0 border-solid border-t-borderGrayDark top-auto bottom-0'>
                <Container maxWidth="false">
                    <div className='py-5 flex justify-end items-center'>
                        <div className='flex justify-between w-full'>
                            <div>
                                <ButtonOutlineImg btnName="Download Selected" variant={'primaryOutline'} img={download} type="button" classes="mr-5" onClick={multipleDownload} disabled={false}/>
                                <ButtonOutlineImg btnName="Delete Selected" variant={'redOutline'} img={trash} type="button" onClick={deleteMutiple} disabled={false}/>
                                <Typography variant={'textBase'} component={'span'} className='ml-5 font-semibold'>{`${selectedDelete.length} File${selectedDelete.length === 1 ? '':'s'} selected`}</Typography>
                            </div>
                            <div>
                                <ButtonSecondary btnName="Review Selected"  variant={'secondaryBtn'} type="submit" onClick={multipleReview} disabled={selected.length > 0?false:true}/>
                            </div>
                        </div>
                    </div>
                </Container>
            </AppBar> 
            ):
            ('')
            } 
        </ThemeProvider>
    );
}

export default DocSummary;