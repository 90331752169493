import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';

import DisconnectIcon from '../../assets/disconnect-tag.gif'

const ApproveConfirmModal = ({openApproveConfirm, setOpenApproveConfirm, postApprove, disconnectedCount}) =>{


    const approve = () =>{

        console.log('openApproveConfirm');
        setOpenApproveConfirm(false);
        postApprove();
    }

    

    return(
        <div>
            <Modal
                open={openApproveConfirm}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-disconnect']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={DisconnectIcon} alt='disconnect icon' className={ModalStyle["icon-img"] }/>
                            </div>
                                <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                    There are {disconnectedCount} tags disconnected
                                </Typography>
                        </div>
                        <div className={`${ModalStyle['footer-wrapper-center']} modal-mt-8`}>
                            <ButtonSecondary btnName="Approve Anyway" type="button" variant={'redBtn'} classes="mr-1" onClick={approve} disabled={false}/>
                            <ButtonSecondary btnName="Back to Review" variant={'primaryBtn'} type="button" classes="ml-1" onClick={() => setOpenApproveConfirm(false)} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default ApproveConfirmModal;