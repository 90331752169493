import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { useForm } from "react-hook-form";
import SimpleBar from 'simplebar-react';
import { useOutletContext } from 'react-router-dom';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from '../../context/User_Access';

import {ThemeProvider, Typography, FormControl, OutlinedInput, InputLabel} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';
import SklInput from '../skeletons/Skl_input';
import ButtonOutlineImg from '../button/Btn_outline_img';
import ButtonSecondary from '../button/Btn_secondary';

import EmailIcon from '../../assets/Email.svg'; 
import LockIcon from '../../assets/Padloack.svg';

const UserSettings = ({openFail, textFail, openSuccess, textSuccess}) =>{

    const { register: nameForm, handleSubmit: handleSubmitName, formState: { errors: errorsName }, reset:resetName, setValue: setValueName } = useForm();
    const { register: emailForm, handleSubmit: handleSubmitEmail, formState: { errors: errorsEmail }, reset:resetEmail , setValue: setValueEmail} = useForm();
    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 
    const [updatedEmail, setUpdatedEmail] = useState('');
    const [updatedName, setUpdatedName] = useState('');
    const [loader, setLoader] = useState(true)
    const {tokenReview} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const { logout } = useAuth0();
    const {setUserDetails} = useUserContext()

    const [cookieOptions, setCookieOptions] = useState({})

    useEffect(()=>{

        const domain = window.location.hostname
        const baseDomain = domain.split('.').slice(-2).join('.');
        const cookieDomain = domain === 'localhost' ? 'localhost' : `.${baseDomain}`;
        const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;

        setCookieOptions(
            {
                path: '/',
                secure: isSecure, 
                sameSite: 'lax', // Use 'lax' to balance security and accessibility
                //domain: cookieDomain,
                
            }
        )
        
    },[])

    useEffect(()=>{
        console.log('cookieOptions >',cookieOptions)
    },[cookieOptions])


    useEffect(() => {

        if(Cookies.get('userName')){
            setValueEmail('email', Cookies.get('userEmail'))
            setValueName('name', Cookies.get('userName'))
        }

        setTimeout(()=>{
            setLoader(false)
        },500)

    },[])   


    //data update

    const userDataUpdate = async (data, fieldVal) => {

        console.log('data update', data, fieldVal);
        
        try {
            const auth0Id = Cookies.get('auth0Id') || null;
            const dataMerge = { ...data, auth0Id };
            
            const res = await axiosInstance.put(`/user_settings.php?submitType=${fieldVal}`, dataMerge);
            const { result, error } = res.data;
        
            console.log(result.message);
            setOpenSuccess(true);
            setTextSuccess(result.message);
        
            if (fieldVal === 1) {
                handleNameUpdate(result.updatedName);
                setUserDetails(result.updatedName, undefined, undefined, undefined, undefined, undefined)
            } else if (fieldVal === 2) {
                handleEmailUpdate();
            }
        
        } catch (err) {
            console.error('fetchData:', err);
            // Only set the alert if it hasn't been set by the interceptor
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                setTextFail(err?.response?.data?.error?.text || 'The server encountered an error and could not complete your request.');
            }
        }     

    };
    
    const handleNameUpdate = (updatedName) => {
        
        const currentName = Cookies.get('userName');
        if (updatedName !== currentName) {
        Cookies.set('userName', updatedName, cookieOptions);
        }
        resetName({ name: updatedName });
    
    };
    
    const handleEmailUpdate = () => {

        logoutFun();
        const currentEmail = Cookies.get('userEmail');
        if (updatedEmail !== currentEmail) {
            Cookies.set('userEmail', updatedEmail, cookieOptions);
        }
        resetEmail({ email: updatedEmail });
        
    };
    

    // const userDataUpdate = async(data, fieldVal) => {

    //     console.log('data update', data, fieldVal)


    //     try {

    //         const dataMerge = {...data, ...{'auth0Id':Cookies.get('auth0Id')?Cookies.get('auth0Id'):null}}
            
    //         const res = await axiosInstance.put(`/user_settings.php?submitType=${fieldVal}`, dataMerge)
            
    //         let result = res.data.result;
    //         let error = res.data.error;

    //         console.log(result.message)
    //         setOpenSuccess(true)
    //         setTextSuccess(result.message)
    //         if(fieldVal === 1){
    //             if(updatedName === Cookies.get('userName')){
    //                 resetName({name:`${Cookies.get('userName')}`})
    //             }
    //             else{
    //                 Cookies.set('userName', updatedName, cookieOptions)
    //                 resetName({name:`${updatedName}`})
    //             }
    //             setUserDetails(result.updatedName, undefined, undefined, undefined, undefined, undefined)
    //         }
    //         else if(fieldVal === 2){
    //             logoutFun()
    //             if(updatedEmail === Cookies.get('userEmail')){
    //                 resetEmail({email:`${Cookies.get('userEmail')}`})
    //             }
    //             else{
    //                 Cookies.set('userEmail', updatedEmail, cookieOptions)
    //                 resetEmail({email:`${updatedEmail}`})
    //             }
    //         }
            

    //     } catch (err) {
    //         console.error('fetchData:', err);
    //         setOpenFail(true)
    //         err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
    //     }     

    // }


    const passwordUpdate = () => {
        userDataUpdate({}, 0)
    }

    const onSubmitName = (data) => {
        console.log('changeName')
        userDataUpdate(data, 1)

    }

    const onSubmitEmail = (data) => {
        console.log('changeEmail')
        userDataUpdate(data, 2)

    }

    //logout when email change

    const logoutFun = async () =>{

        console.log('logout');

        // logout({ 
        //     logoutParams: { returnTo: `${window.location.origin}/` } 
        // })

        try {

            const res = await axiosInstance.get(`/logout.php`)
                
            let data = res.data.result;
            let error = res.data.error;

            console.log('data sign out', data)
            logout({ 
                logoutParams: { returnTo: `${window.location.origin}/` } 
            })
            //navigate('/signin');
            Cookies.remove('token', cookieOptions);
            Cookies.remove('expire', cookieOptions);
            Cookies.remove('refreshToken', cookieOptions);
            Cookies.remove('userEmail', cookieOptions);
            Cookies.remove('userName', cookieOptions);
            Cookies.remove('userId', cookieOptions);
            Cookies.remove('access', cookieOptions);
            Cookies.remove('auth0Connection', cookieOptions);
        } 
        catch (err) {
            console.error('fetchData:', err);
        }  

    }

    useEffect(()=>{console.log('tokenReview region',tokenReview)},[tokenReview])
    
    return(
        <ThemeProvider theme={ThemeCustom}>
            
            <SimpleBar className='min-h-user-setting-right-active'>
            <div className='mb-12'>
                <div className='flex justify-between items-start'> 
                    <div>
                        <Typography variant="text32" className="mb-3" component='div'>
                            User Settings
                        </Typography>
                        <Typography variant="text20" className="mb-3" component='div'>
                            Change user details
                        </Typography>
                    </div>
                </div>
            </div>
            <div className='max-w-[38rem]'>
                <form onSubmit={handleSubmitName(onSubmitName)}>
                    <FormControl variant="standard" fullWidth  margin="normal" className='mt-0' >
                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                            Name
                        </InputLabel>
                        <div className='flex items-center'>
                        {loader? (
                            <SklInput/>
                        ):(
                        <>
                        <OutlinedInput 
                            id='name'
                            placeholder='Enter name'
                            //value={regionName}
                            autoComplete="off" 
                            type='text'
                            className='modal-input'
                            //{...register('name',{ required: true , onChange: (e) => setRegionName(e.target.value) })} 
                            {...nameForm('name',{ required: true, onChange:(e)=> setUpdatedName(e.target.value) } )} 
                        />
                        </>
                        )}
                            <ButtonSecondary btnName="Change" variant={'primaryBtn'} classes="ml-5 py-[1.125rem]" type="submit" onClick={()=>{}} disabled={false}/>
                        </div>
                        {errorsName.name && <p className='err-msg'>Name is required</p>}
                    </FormControl>
                </form>
                <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
                    <FormControl variant="standard" fullWidth  margin="normal" className='mt-12' >
                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                            Email address
                        </InputLabel>
                        <div className='flex items-center'>
                            {loader? (
                                <SklInput/>
                            ):(
                            <>
                            <OutlinedInput 
                                id='email'
                                placeholder='Enter email address'
                                //value={regionName}
                                autoComplete="off" 
                                type='text'
                                className='modal-input'
                                {...emailForm('email',{ 
                                    required: true, 
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    }, 
                                    onChange:(e)=> setUpdatedEmail(e.target.value) 
                                })} 
                            />
                            </>
                            )}
                            <ButtonSecondary btnName="Change" variant={'primaryBtn'} classes="ml-5 py-[1.125rem]" type="submit" onClick={()=>{}} disabled={false}/>
                        </div>
                        {errorsEmail.email && errorsEmail.email.type === "required" && <p className='err-msg'>Email is required</p>}
                        {errorsEmail.email && errorsEmail.email.type === "pattern" && <p className='err-msg'>Invalid email address</p>} 
                    </FormControl>
                </form>

                {
                    Cookies.get('auth0Connection') && Cookies.get('auth0Connection') == 'Username-Password-Authentication' ? (
                        <div className='mt-12 mr-[8.75rem]'>

                            <Typography variant="textBase" component='div' className='mb-2 mt-12'>
                                Password
                            </Typography>
                            <ButtonOutlineImg btnName="Change Password" variant={'redOutline'} img={LockIcon} type="button" classes="min-w-[16rem] w-full" onClick={passwordUpdate} disabled={false}/>

                        </div>
                    ):''
                }
                
            </div>
            </SimpleBar>
        </ThemeProvider>
    )
}

export default UserSettings;