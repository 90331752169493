import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from 'simplebar-react';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useUserContext } from '../../context/User_Access';

import {ThemeProvider, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Tooltip} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

import ButtonSecondary from '../button/Btn_secondary';
import NotFound from '../masseges/Not_found';
import Loading from '../masseges/Loading';

const Doc = [
    {internalTag:"<Buyer><Country></Country>", destinationFormat:"UBL 2.1", request:"02"}, 
    {internalTag:"<Buyer><VATNo></VATNo>", destinationFormat:"PEPPOL BIS 2.3", request:"03"},
    {internalTag:"<Seller><Address>Dash Invest, HYLLIE VATTENPARKSGATA 11 A</Address></Seller>", destinationFormat:"Pagero Universal Format", request:"01"}, 
]


const UnKnowMapTable = (props) => {

    const [docs, setDocs] = useState(Doc);
    const scrollableNodeRef = useRef();
    const [notFound, setNotFound] = useState(false);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const {devMode} = useUserContext()

    useEffect(()=>{
        console.log('not found',docs);
        if(docs.length === 0){
            setNotFound(true)
            console.log('not found if',docs);
        }
        else{
            setNotFound(false)
            console.log('not found else',docs);
        }
        docCount();
    },[docs])

    const docCount = () => {
        console.log('docs.length', docs.length);
    }

    const mapNow = () => {
        if(devMode){
            navigate('/settings/known_mappings?devMode=true');
        }else{
            navigate('/settings/known_mappings');
        }
    }


    return(
        <ThemeProvider theme={ThemeCustom}>

            <div className={` relative border-solid border border-borderGrayDark rounded-xl`}>
                <SimpleBar className='min-h-unknownMap rounded-xl'>
                {loader ||  docs.length === 0 ? (  

                    <Loading/>
                    
                    ): notFound  ? (  
                    
                        <NotFound msgText={'No documents found!'}/>

                    ):(
                    <TableContainer >
                        <Table stickyHeader aria-label="unknown map Datasets">  
                            <TableHead>
                                <TableRow>
                                    <TableCell>Internal Format Tag</TableCell>
                                    <TableCell>Destination format</TableCell>
                                    <TableCell>Requests</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { docs.length > 0 && docs.map((doc, index) => (
                                <TableRow key={index} >
                                    <TableCell className='max-w-[30vw] truncate cursor-pointer'>
                                        <Tooltip title={doc.internalTag} arrow placement="bottom">
                                            <span>{doc.internalTag}</span>
                                        </Tooltip>    
                                    </TableCell>
                                    <TableCell>{doc.destinationFormat}</TableCell>
                                    <TableCell>{doc.request}</TableCell>
                                    <TableCell className={`text-center lg:max-w-[10rem]`}>
                                        <ButtonSecondary variant={'secondaryOutline'} type='button' onClick={mapNow} btnName='Map Now' classes="w-full max-w-[6rem] px-4 py-3" />
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                </SimpleBar>
            </div>
            
        </ThemeProvider>
    );
}

export default UnKnowMapTable;