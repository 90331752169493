import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklVariableList = (props) => {


    return(
        <ThemeProvider theme={ThemeCustom}>
            {[0,1].map((block,index)=>(
                <div key={index}>
                <Skeleton animation="wave" className={`w-[5rem] max-h-[1.5rem] h-[1.5rem] rounded my-4`}></Skeleton>
                {[0,1,2,3,4,5].map((item,i)=>(
                    <div className='' key={i}>
                        <div className='p-3 border-b border-borderGrayDark border-solid border-0 flex justify-between items-center'>
                            <Skeleton animation="wave" className={`w-[12rem] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>
                            <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] rounded`}></Skeleton>    
                        </div>
                    </div>
                ))}
            </div>
            ))}
        </ThemeProvider>
    )
}

export default SklVariableList;