import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import {Container, Typography} from '@mui/material';

import CreateAccountForm from '../components/auth-form/Create_account_form';
import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';

import loaderIcon from '../assets/Loading1.svg'

const CreateAccount = (props) =>{

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState('');
    const { loginWithRedirect } = useAuth0();

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);

    useEffect(()=>{
        console.log(openSuccess)
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    //redirect to auth login

    useEffect(()=>{
        
        loginWithRedirect()

    },[])

    return(
        <>
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
            {/* <Container maxWidth="xl">
                <div className='flex justify-center items-center h-[calc(100vh-9rem)] mt-12 relative'>
                    <CreateAccountForm 
                        setOpenSuccess={setOpenSuccess} 
                        setTextSuccess={setTextSuccess} 
                        setOpenFail={setOpenFail} 
                        setTextFail={setTextFail}
                    />
                </div>
                <div className='text-center h-12 flex justify-center mb-12'>
                    <div className='my-3 px-3 flex items-center text-base text-sonicSilver font-medium'>
                        By signing up, you agree to the <a href='#' target='_blank' className=' text-sonicSilver underline mx-1'>Terms of Service</a> and <a href='#' target='_blank' className=' text-sonicSilver underline ml-1'>Privacy Policy</a>
                    </div>
                </div>
            </Container>  */}
             <Container maxWidth="xl">
                <div className={`${props.class? props.class:'h-screen'} flex justify-center items-center flex-col`}>
                    <div className='flex flex-col items-center justify-center'>

                        <img className="max-w-[2.5rem] min-h-[2.5rem]" src={loaderIcon} alt="loading logo"/>
                        <Typography variant="text24" className="!font-medium mt-7" component='div'>
                            Please wait...
                        </Typography>
                        
                    </div>
                </div>
            </Container> 
        </> 
    );
}

export default CreateAccount;