import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, Select, MenuItem} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';


const  MappingUploadModal = ({openMapUpload, setOpenMapUpload, setFilter, formatApi, destinationFormatGet, setOpenSuccess, setTextSuccess, setOpenFail, setTextFail}) =>{

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [uploading, setUploading] = useState(false)
    const [fileType, setFileType] = useState('')
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(()=>{
        setUploading(false)
    },[])

    const cancel = () =>{
        setOpenMapUpload(false)
        destinationFormatGet()
        reset({name:'', type:''})
        setFileType('')
    }

    const submit = () =>{
    }

    const mappingDownload = () =>{
        console.log('mappingDownload');
        window.open(`${BaseUrl}/download.php?type=mappings_template`, '_blank', 'noopener noreferrer');
    }

    const onSubmit = async(data) => {

        setUploading(true)
        setBtnLoading(true)

        try {

            const res = await axiosInstance.post(`/upload_mappings.php`, data)

            let result = res.data.result;
            let error = res.data.error;
                
            console.log(res.data.result)
            setFilter(result.destinationFormat.value)
            setUploading(false)
            setOpenMapUpload(false)
            reset({name:'', type:''})
            setFileType('')
            formatApi()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            setUploading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }  

    }

    const filterFormatApply = () => {

    }

    return(
        <div>
            <Modal
                open={openMapUpload}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-mapping']} select-modal`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                    Add new mappings
                                </Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Name
                                        </InputLabel>
                                        <OutlinedInput 
                                            id='name'
                                            placeholder='Enter mapping name'
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            {...register('name',{ required: true })} 
                                        />
                                        {errors.name && <p className='err-msg'>Name is required</p>}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='form-control modal-mb-6 mapping-file-dropdown'>
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            File type
                                        </InputLabel>
                                        <Select
                                            className='select type-filter-select'
                                            value={fileType}
                                            onChange={filterFormatApply}
                                            displayEmpty
                                            inputProps={{'aria-label': 'Document fromatting type'}}
                                            input={<OutlinedInput />}
                                            {...register('type',{ required: true, onChange:(e) => setFileType(e.target.value) })} 
                                        >   
                                            <MenuItem className='menu-item' disabled value={''}>-Select format-</MenuItem>
                                            <MenuItem value={'xml'} className='menu-item'>XML</MenuItem>
                                            {/* <MenuItem value={2} className='menu-item'>Purchase order</MenuItem> */}
                                        </Select>
                                        {errors.type  && fileType === '' && <p className='err-msg'>File type is required</p>}
                                    </FormControl>
                                    
                                    {/* <FormControl variant="standard" fullWidth  margin="normal" className='form-control filepond-modal' >
                                        <div className='upload-input-text-wrapper'>
                                            <InputLabel shrink htmlFor='namex' title='text' className='modal-label'>
                                                Upload
                                            </InputLabel>
                                            <Typography component="div" className='download-text' onClick={mappingDownload}>
                                                Download Mapping
                                            </Typography>
                                        </div>
                                        <FilePond
                                            className='filepond-btn-modal'
                                            files={file}
                                            allowReorder={false}
                                            allowMultiple={false}
                                            onupdatefiles={setFile}
                                            credits={false}
                                            labelIdle={`<div class="drag-text-wrapper">Drag & Drop your mappings or <span class="text-underline">Browse</span></div>`}   
                                            {...register('mapFile',{ required: file.length === 0 ? true : false })}
                                        />
                                        {errors.mapFile && <p className='err-msg'>This field is required</p>}
                                    </FormControl> */}
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName="Submit" variant={'secondaryBtn'} classes='modal-button-12' type="submit" onClick={submit} disabled={uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName="Submit" btnLoading={btnLoading} disabled={uploading}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default MappingUploadModal;