import React, {useState, useEffect} from 'react';

import { ThemeProvider, Button, FormControl, Select, MenuItem, OutlinedInput, InputAdornment } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";
import './Add-dropdown.scss'

import SearchIcon from '../../assets/search.svg'
  

const AddDropdownOrg = (props) => {

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownItem, setDropdownItem] = useState([]);
    const [searchFile, setSearchFile] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [orgVal, setOrgVal] = useState('')

    //set dropdown items 

    useEffect(() => {
        console.log('files preveiw dd', props.orgList);

        if(props.orgList){
            const newOrgArray =  newSearchField(props.orgList)

            setDropdownItem(newOrgArray)
            setSearchFile(newOrgArray)
            console.log('files preveiw xxxxx', newOrgArray);
        }

    }, [props.orgList]);

    const newSearchField = (orgList) => {

        return orgList?.reduce((acc, { id, orgName, subOrganisations}) => {
            // Add the main org object
            acc.push({ id, orgName, type:'mainOrg' });
          
            // Add each sub org object
            subOrganisations.forEach(({ id, orgName }) => {
              acc.push({ id, orgName, type:'subOrg' });
            });
          
            return acc;
        }, []);

    }

    useEffect(()=>{
        console.log('##### searchFile > ',searchFile, 'dropDownItem > ',dropDownItem, 'notFound >', notFound )
    },[searchFile, dropDownItem, notFound])

    //item search

    useEffect(()=>{

        if(dropDownItem.length > 0){
            const filteredItems = dropDownItem.filter((item) =>
            item.orgName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchFile(filteredItems);
            console.log('filteredItems', filteredItems);
        }

    },[searchTerm])

    //item not found in search

    useEffect(() => {
        console.log("searchFile.length",searchFile.length);
        if(dropDownItem.length > 0 && searchFile.length === 0){
            setNotFound(true)
        }
        else{
            setNotFound(false)
        }
    }, [searchFile])

    //dropdown close

    const handleClose = () => {
        setDropDownOpen(false);
        setTimeout(()=>{
            setSearchTerm('');
        },1000)    
    };
    
    //dropdown open

    const handleOpen = () => {
        setDropDownOpen(true);
    };

    //select item

    const handleChange = (e) => {

        console.log('files preveiw xxxxx handleChange', e.target.value)

        if(!(e.target.value[e.target.value.length - 1] === undefined && e.target.value.length === 1)){
            //props.setAddField(e.target.value);
            const resetSearchFile = newSearchField(props.orgList)
            setSearchFile(resetSearchFile)
            setSearchTerm('');
            setDropDownOpen(false);

            //get new data for buyer/seller

            props.getBuyerData(e.target.value)
            props.setSelOrgId(e.target.value)
            //setOrgVal(e.target.value)

        }
        
        console.log('target', e.target.value)
    };




    return(
        <ThemeProvider theme={ThemeCustom}>
            <>
                <span className='font-semibold underline cursor-pointer px-1' onClick={handleOpen}>click here</span> 
                <FormControl className='invisible h-0 w-0 m-0 addField mt-5'>
                    <Select
                        labelId="organization-list-label"
                        id="organization-list"
                        open={dropDownOpen}
                        onChange={handleChange}
                        onClose={handleClose}
                        onOpen={handleOpen}
                    >
                        <div>
                            <FormControl variant="standard"  margin="normal" className='dropdown-serach'>
                                <OutlinedInput className='search-input rounded-xl'
                                    autoComplete="off" 
                                    placeholder="Search" 
                                    type='text'
                                    value={searchTerm}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt='icon' className='icon-w-5'/>
                                        </InputAdornment>
                                    }
                                    onChange={(e) => {setSearchTerm(e.target.value)}}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </FormControl>
                        </div>
                        {dropDownItem?.length > 0 && !notFound ? (
                        
                        searchFile.map((org) => (
                            <MenuItem
                                onKeyDown={(e) => e.stopPropagation()}
                                className={`fieldDropdownItem org-list-item ${org.type}`}
                                key={org.orgName}
                                value={org.id}                           
                            >
                                <span className='text-item-li'>
                                    {org.orgName}
                                </span>
                            </MenuItem>
                        ))):(
                            <MenuItem
                                onKeyDown={(e) => e.stopPropagation()}
                                className='fieldDropdownItem no-bg-img'
                                onClick={(e) => e.stopPropagation()}
                                disableRipple
                            >
                                No result found!
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </>    
        </ThemeProvider>    
    );
}

export default AddDropdownOrg;