import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';


const VerifyOutputModal = ({openVerifyOutput, setOpenVerifyOutput, setValue, formDataPost}) =>{

    const switchToOutput = () =>{
        console.log('switchToOutput')
        setValue(1)
        setOpenVerifyOutput(false)
        formDataPost()
    }

    return(
        <div>
            <Modal
                open={openVerifyOutput}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-verfy-output']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                Verify destination format
                            </Typography>
                            <Typography id="modal-modal-description" className={ModalStyle['modal-text-base']} align={'center'} variant="textBase" component="p">
                                Click the "Destination" tab to confirm document compatibility with your destination format.
                            </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Okay, got it!" variant={'secondaryBtn'} type="submit" onClick={switchToOutput} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default VerifyOutputModal;