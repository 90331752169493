import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";

const ButtonSecondary = (props) => {
    return (
        <ThemeProvider theme={ThemeCustom}>
          <Button variant={props.variant} type={props.type} className={`${props.classes} btn-two-state`} onClick={props.onClick} disabled={props.disabled}>
            {props.btnName}</Button>
        </ThemeProvider>
    );
}

export default ButtonSecondary;