import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss'

//const BaseUrl = 'https://docupath.app';


const DataRetainModal = ({openDataRetain, setOpenDataRetain, sendDataRetain, btnLoading}) =>{
    
    return(
        <div>
            <Modal
                open={openDataRetain}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-delete']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                Attention!
                            </Typography>
                            <Typography id="modal-modal-description" className={`modal-mt-5`} align={'center'} variant="textBase" component="p">
                                Adjusting the retention period will cause older documents to be deleted. 
                            </Typography>
                            <Typography id="modal-modal-description" className={` modal-mt-8`} align={'center'} variant="textBase" component="p">
                                Do you wish to proceed?
                            </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Not Now" type="button" variant={'platinumBtn'} classes="mr2" onClick={() => setOpenDataRetain(false)}/>
                            <ButtonSecondaryLoader variant={'redBtn'} type='button' btnName='Yes, Proceed' btnLoading={btnLoading} disabled={false} onClick={sendDataRetain}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default DataRetainModal;