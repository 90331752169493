import React, {useState, useEffect} from 'react';

import {ThemeProvider, Typography } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

const TextViewer = (props) => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <Typography component={'div'} variant='text14' className='p-5 pt-10 bg-cultured whitespace-pre-line break-words min-height-grid-pdf-viewer'dangerouslySetInnerHTML={{__html:props.fileText}} />
        </ThemeProvider>
    )

}

export default TextViewer;