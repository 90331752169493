import React, {useState, useEffect} from 'react';
import SimpleBar from 'simplebar-react';
import { useOutletContext } from 'react-router-dom';

import {ThemeProvider, Typography} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';
import UnKnowMapTable from './Unknown_map_table';

const UnKnowMap = ({openFail, textFail, openSuccess, textSuccess }) =>{

    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 

    const {devMode} = useOutletContext(); 

    
    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='mb-12'>
                <Typography variant="text32" className="mb-3" component='div'>
                    Unknown mappings
                </Typography>
                <Typography variant="text20" className="mb-3" component='div'>
                    Map fields from your internal format to the destination format
                </Typography>
            </div>
                <div>

                    <UnKnowMapTable devMode={devMode}/>

                </div>
         
        </ThemeProvider>
    )
}

export default UnKnowMap;