import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";
import './Button.scss';

const ButtonOutlineImg = (props) => {
    return (
        <ThemeProvider theme={ThemeCustom}>
          <Button variant={props.variant} type={props.type} className={props.classes} onClick={props.onClick} disabled={props.disabled}>
            <img src={props.img} alt="icon" className="me-3 max-w-[1.5rem]"/>
            {props.btnName}</Button>
        </ThemeProvider>
    );
}

export default ButtonOutlineImg;