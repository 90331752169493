import React from "react";

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import InputStyle from './Input_styles.module.scss';
import './Drag-input_styles.scss';

import UploadLogo from '../../assets/upload-logo.svg'

// Register the plugins
registerPlugin(FilePondPluginFileValidateType);


const DragDropImg = (props) => {


    return (
        <ThemeProvider theme={ThemeCustom}>
            <div className="w-full rounded-xl" >
                <div className={` ${InputStyle["upload-more"]} file-pond-custom upload-more-custom upload-screen logo-upload relative`}>
                    <div className={`${InputStyle["file-upload-container"]} flex items-end  min-h-[6rem]`}>
                        <FilePond
                            files={props.files}
                            allowReorder={false}
                            allowMultiple={false}
                            acceptedFileTypes={['svg, png']}
                            onupdatefiles={props.setFiles}
                            labelIdle={`<div class="flex justify-start items-center"><img src=${UploadLogo} width="72" height="72" alt="upload-animation" class='pr-4'/><div class="flex flex-col justify-start"><div class="text-primary font-semibold text-base mb-2 text-left">Drag & Drop your Logo or <span class="text-primary underline cursor-pointer">Browse</div><div class="text-sonicSilver font-medium text-sm">SVG or PNG (300 x 120px recommended)</div></div></div>`}
                        />
                        
                    </div>
                </div>
            </div>
        </ThemeProvider>    
    );
}

export default DragDropImg;