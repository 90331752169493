import React, {useState, useEffect, useRef } from 'react';
import { useUserContext } from '../../context/User_Access';
import {Typography, Tabs, Tab, Skeleton, Collapse} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SimpleBar from 'simplebar-react';

import 'simplebar-react/dist/simplebar.min.css';

import FormInput from "./Form_input";
import XmlViewer from './XML_viewer';
import PDFViewer from './Pdf_viewer';
import TextViewer from './Text_viewer';
import FeatureSoonModal from '../modal/Modal_feature_soon';
import SklXmlViewer from '../skeletons/Skl_xmlviewer';
import ButtonOutline from '../button/Btn_outline';
import AddDropdownOrg from '../invoice-input/Add_dropdown_org';

import loaderIcon from '../../assets/Loading1.svg';
import InfoIcon from '../../assets/info-green.svg'
import CompliantIcon from '../../assets/compli-alert.svg'
import WarnIcon from '../../assets/bold-danger-red.svg'
import WarnDefIcon from '../../assets/bold-danger.svg'



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const InputTab = (props) => {

    const [OpenCommingSoon, setOpenCommingSoon] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const pdfContainer = useRef(null);
    const simpleBarRef = useRef(null);
    const {devMode} = useUserContext()
    const [timerStartFrom, setTimerStartFrom] = useState(0)



    useEffect(()=>{
        //console.log('OpenCommingSoon',OpenCommingSoon)
    },[OpenCommingSoon])
    
    //XML viewer relates

    useEffect(() => {
        console.log('props.dataFiles', props.dataFiles)
        if (props.dataFiles.length > 0) {
          const filteredItems = JSON.parse(props.dataFiles).map(item => {
            if(searchText !== ''){
                if (item.line.includes(searchText)) {
                    return { ...item, class: 'color' };
                  }
            }
            return item;
          });
    
          //console.log('filteredItems', filteredItems);
          setFilteredData(filteredItems);
        }
    
    }, [searchText, props.dataFiles]);

    //xml viewer scroll handle

    const handleScroll = (offsetTop) => {
        if (simpleBarRef.current) {
          simpleBarRef.current.getScrollElement().scrollTop = offsetTop;
        }
    };

    //pdf or text preview

    const handleChange = (event, newValue) => {
        props.setDocPreview(newValue);
    };

    //changes save

    const saveChanges = () => {

        props.formDataPost();
    }

    //concurrent user access handling

    useEffect(()=>{

        if(props.conCurType === 0){
            props.setEditDenyTimmer(60)
            setTimerStartFrom(60)
        }
        
    },[props.conCurType])


    useEffect(()=>{

        console.clear()

        if(props.editDenyTimmer){

            const Timmer = setInterval(()=>{
                props.setEditDenyTimmer((prevSeconds) => {
                    if (prevSeconds > 0) {
    
                        return prevSeconds - 1;
    
                    } else {
    
                        clearInterval(Timmer);
                        setTimerStartFrom(0)
                        return 0;
    
                    }
                });
            },1000)

            return () => clearInterval(Timmer);

        }
        else{

            if(props.editDenyTimmer === 0){
                //console.log('conCurType editDenyTimmer else')
                if (props.editDenyTimmer < 1) {
                    if(props.conCurType === 0 ){
                        //console.log('conCurType set 1')
                        if(timerStartFrom === 15){
                            props.setConCurType(null)
                            props.setEditRequested(false)
                        }
                        else{
                            props.setEditRequested(false)
                            props.setConCurType(1)

                        }
                    }
                    //console.log('conCurType editDenyTimmer')
                }
            }

        }

    },[props.editDenyTimmer])


    //requets edit acceesss for concurrent user

    const requestEditAccess = () => {
        props.setEditDenyTimmer(15)
        props.setEditRequested(true)
        //console.log('requestEditAccess')
        props.webSocketReqest()
        setTimerStartFrom(15)
    }

    useEffect(()=>{
        console.log('requester id', props.requesterId)
    },[props.requesterId])

    return(
        <>
         <FeatureSoonModal
            OpenCommingSoon={OpenCommingSoon}
            setOpenCommingSoon={setOpenCommingSoon}
         />
            <Grid container spacing={2}>
                <Grid xs={12} md={6} className="md:border-r border-0 md:border-solid md:border-r-borderGrayDark p-0">
                    <div className={`m-5 border-solid border border-borderGrayDark rounded-xl`}>
                        <div className={`${props.checkStatus == 'no_org' || props.formModify || props.conCurType ||  props.conCurType === 0? 'mt-5 mx-5 mb-3' : ''}`}>
                            <Collapse in={props.conCurType === 0 || props.conCurType === 1 }>
                                <div className='pb-2'>
                                    <div className='bg-latte py-4 px-5 rounded-xl font-sans flex justify-between items-center'>
                                        <div className='flex items-center'>
                                        <img src={WarnDefIcon} alt='info' className='w-6 h-6 mr-5'/>
                                        {props.conCurType === 0 ? (
                                            <div className='text-primary text-sm font-medium font-sans'>
                                                Access denied please wait for {props.editDenyTimmer}s to request edit access. 
                                            </div>
                                        ): props.conCurType === 1? (
                                            props.editRequested ? (
                                                props.docViwerMessage ? (
                                                    <div className='text-primary text-sm font-medium font-sans'>
                                                        {props.docViwerMessage}. 
                                                        <span className='font-semibold px-1'>Pending access {props.editDenyTimmer}s access</span>
                                                    </div>
                                                ):''
                                            ):(
                                                props.docViwerMessage ? (
                                                    <div className='text-primary text-sm font-medium font-sans'>
                                                        {props.docViwerMessage}. 
                                                        <span className='font-semibold underline cursor-pointer px-1' onClick={requestEditAccess}>Request edit access</span>
                                                    </div>
                                                ):''
                                            )
                                        ):'' }
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={props.formModify}>
                                <div className='pb-2'>
                                    <div className='bg-latte py-4 px-5 rounded-xl font-sans flex justify-between items-center'>
                                        <div className='flex items-center'>
                                        <img src={CompliantIcon} alt='info' className='w-6 h-6 mr-5'/>
                                        <div className='text-primary text-sm font-medium font-sans'>
                                            Heads up! 
                                            <div>You've made some changes. Don't forget to save them.</div>
                                        </div>
                                        </div>
                                        <div>
                                            <ButtonOutline btnName="Save Changes" variant={'primaryOutline'} type="button" classes="ml-5 text-sm min-w-[10rem]" onClick={saveChanges} disabled={false}/>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={props.checkStatus == 'no_org'}>
                                <div className='pb-2'>
                                    <div className='bg-linen py-4 px-5 rounded-xl font-sans flex justify-between items-center'>
                                        <div className='flex items-center'>
                                            <img src={WarnIcon} alt='info' className='w-6 h-6 mr-5'/>
                                            <div className='text-puceRed text-sm font-medium font-sans'>
                                                <div>Oops! It looks like the buyer details don't match any organizations in our settings.</div> 
                                                Please 
                                                <AddDropdownOrg
                                                    orgList={props.orgList}
                                                    getBuyerData={props.getBuyerData}
                                                    setSelOrgId={props.setSelOrgId}
                                                /> 
                                                to select the correct organization.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <SimpleBar className={(props.formModify &&  props.checkStatus == 'no_org') ? 'min-height-grid-collapse-both': props.formModify ? 'min-height-grid-collapse' : props.checkStatus == 'no_org'?'min-height-grid-no_org': `min-height-grid`}>
                            <FormInput
                                jsonInvoiceText={props.jsonInvoiceText}
                                setJsonInvoiceText={props.setJsonInvoiceText}
                                jsonInvoiceTextOriginal={props.jsonInvoiceTextOriginal}
                                jsonInvoiceTable={props.jsonInvoiceTable}
                                setJsonInvoiceTable={props.setJsonInvoiceTable}
                                position={props.position}
                                addField={props.addField}
                                setJsonInvoice={props.setJsonInvoice}
                                setAddField={props.setAddField}
                                readonly={props.readonly}
                                fieldNameList={props.fieldNameList}    
                                setSearchText={setSearchText}
                                setOpenCommingSoon={setOpenCommingSoon}
                                changeLogData={props.changeLogData}
                                setFormModify={props.setFormModify}
                                formModify={props.formModify}
                                formDataPost={props.formDataPost}
                                sklFormView={props.sklFormView}
                                setSklFormView={props.setSklFormView}
                                jsonInvoiceBarcode={props.jsonInvoiceBarcode}
                                inputRefs={props.inputRefs}
                            />
                        </SimpleBar>
                    </div>
                </Grid>
                <Grid xs={12} md={6} className='p-0'>
                    <div className='m-5 mb-[7.5rem] md:mb-5 border-solid border border-borderGrayDark rounded-xl relative edit-hover-no'>
                            {props.docType === 'xml' ?  ( 
                                <div className='scroll-light'>
                                    <SimpleBar className={`min-height-grid rounded-xl ${props.dataFiles === '' ? 'bg-white' : 'bg-primary' }`} ref={simpleBarRef}>

                                            <div className={`${props.dataFiles === '' ? '' : 'hidden'} bg-white overflow-hidden`}>
                                                <SklXmlViewer/>
                                            </div>
                                            <div className={props.dataFiles === '' ? 'hidden' : '' }>
                                                <XmlViewer 
                                                    nestedMap={filteredData}
                                                    handleScroll={handleScroll}
                                                />
                                            </div>
                                    </SimpleBar>
                                </div>
                            ) : ( 
                                <>  
                                    <div className='absolute right-2 top-0 z-50 edit-mode'>
                                        <Tabs value={props.docPreviewMode} className='justify-end' onChange={handleChange} aria-label="image text switch">
                                            <Tab label="Image" disableTouchRipple {...a11yProps(0)} />
                                            <Tab label="Text" disableTouchRipple {...a11yProps(1)} />
                                        </Tabs>
                                    </div>
                                    <SimpleBar className='min-height-grid-pdf-viewer' ref={pdfContainer}>
                                        {props.docPreviewMode === 0 ? (

                                            <PDFViewer 
                                                pdfUrl={props.pdfInvoice} 
                                                searchText={searchText} 
                                                invoiceName={props.invoiceName}
                                                setSklPdfView={props.setSklPdfView}
                                                sklPdfView={props.sklPdfView }
                                            />
                                                   
                                        ):(
                                            <TextViewer fileText={props.textView}/>
                                        )}    
                                    </SimpleBar>
                                </>
                            )} 
                            {devMode?(
                                <div className='absolute bottom-3 right-16 z-50'>
                                    <div className='text-primary bg-infoGreenLight py-3 px-5 text-sm rounded-xl flex items-center font-semibold min-w-[19rem] w-full'>
                                        <img src={InfoIcon} alt='info' className='max-w-[1.25rem] w-full mr-3'/>  
                                        100% Accuracy  <span className='mx-3'>•</span>  92% Captured
                                    </div>
                                </div> 
                            ):''}
                    </div>
                </Grid>
            </Grid>
    </>    
    )
};

export default InputTab;