import React, {useEffect, useState, useCallback} from 'react';
import {ThemeProvider, Tooltip, Box, IconButton, Button } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Delete from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '../../assets/delete.svg'

import MaterialReactTable from 'material-react-table';

import { ThemeCustom} from "../../styles/Theme";
import invoiceStyle from './Invoice-input_style.module.scss';


const InvoiceTable2 = (props) => {
    
      const [tableCell, setTableCell ] =  useState(1);

      //cell edit ###

      const handleSaveCell = (cell, value) => {

        const reArray = [...props.jsonInvoiceTable]

        const targetArray = props.jsonInvoiceTable[props.tablePosition].tableRows;

        console.log('reArray > ', reArray, 'targetArray > ', targetArray)

        if (targetArray) {
          // Access the specified object using objectIndex
          const targetObject = targetArray[cell.row.index];

          if (targetObject) {
            // Update the specified property in the target object
            console.log('handleSaveCell > ', 'targetObject[cell.column.id] xxx > ',cell.column.id, 'targetObject', targetObject, 'targetObject[cell.column.id]', targetObject[cell.column.id], value)

            targetObject[cell.column.id].value = value;
            targetObject.IsUpdated = true;
            //Update the state with the modified array
            props.setJsonInvoiceTable(reArray);

          }
        }

      };

      //read-only styles ###

      useEffect(()=>{
        //console.log('tableData',props.tableRow)
        //console.log(props.columns)
        if(props.readonly){
          setTableCell(0.5);
        }
        else{
          setTableCell(1);
        }
      },[props.readonly]);

      //table row delete ###

      const handleDeleteRow = (row) => {

          console.log('delete', row.index, row)
          const reArray = [...props.jsonInvoiceTable]
          const updatedArray = props.jsonInvoiceTable[props.tablePosition].tableRows.filter((item, index) => index !== row.index)
          console.log('updatedArray', updatedArray, 'props.jsonInvoiceTable[props.tablePosition].tableRows', props.jsonInvoiceTable[props.tablePosition].tableRows)
          //props.setJsonInvoiceTable.splice(row.index, 1)
          props.jsonInvoiceTable[props.tablePosition].tableRows = updatedArray
          console.log('delete reArray', reArray)
          props.setJsonInvoiceTable(reArray);
          props.setFormModify(true)
        
      }

      //add table row 

      const addRow = () => {

        //console.log('props.tablePosition--',props.tablePosition, 'props.tableKey--', props.tableKey, 'props.tableMultiArray--',props.tableMultiArray)
        console.log('tableBug*** addRow tableMultiArray', props.jsonInvoiceTable)
        const reArray = [...props.jsonInvoiceTable];

        const newRow = props.columns.reduce((acc, curr) => {
          acc[curr.accessorKey] = {value:'', defValue:''};
          //console.log('acc xxxxx',acc, curr)

          return acc;

        }, {});

        // Add a unique ID for the new row
        newRow.id = Math.floor(Math.random() * 100000);
        newRow.Key = props.jsonInvoiceTable[props.tablePosition].Key;
        newRow.IsUpdated = true;

        console.log('newRow---', newRow, props.jsonInvoiceTable, props.jsonInvoiceTable[props.tablePosition]);
        reArray[props.tablePosition].tableRows = [...reArray[props.tablePosition].tableRows, newRow]
        props.setJsonInvoiceTable(reArray);
        props.setFormModify(true)

      }
      
      useEffect(()=>{
        console.log('table > ', props.tableArray, props.columns )
      },[props])
    
      return (
        <ThemeProvider theme={ThemeCustom}>
            <div className='w-full'>
              <Box className={`w-full p-2 pt-5 `}>
                <MaterialReactTable
                  className={invoiceStyle['muiTable']}
                  //columns={props.columns}
                  columns={Array.isArray(props.columns) ? [...props.columns, {
                    header: 'Actions',
                    accessorKey: 'actions',
                    Cell: ({ row }) => (
                      <Box sx={{ display: 'flex', gap: '0.25rem', justifyContent: 'start' }}>
                        <Tooltip arrow placement="right" title="Delete">
                          <IconButton className='p-0' disabled={props.readonly} onClick={() => handleDeleteRow(row)}>
                            <img src={DeleteIcon} alt='icon' className='w-5'/>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ),
                    size: 120,
                  }]: []}
                  data={[...props.tableArray]}
                  editingMode="cell"
                  enableEditing={!props.readonly}
                  muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                    //onBlur is more efficient, but could use onChange instead
                    onBlur: (event) => {
                      //handleSaveCell(cell, event.target.value);
                      handleSaveCell(cell, event.target.value);
                      props.setSearchText('')

                    },
                    onFocus: (event) => {
                      props.setSearchText(cell.getValue())
                    },
                    onKeyUp: (event) => {
                      //props.setFormModify(true)
                    }
                  })}
                  enableTopToolbar={false}
                  enableColumnActions={false} 
                  initialState={{density:'compact'}}
                  enablePagination={false}
                  renderBottomToolbarCustomActions={() => (
                    <div className='flex justify-end w-full px-3 py-2'>
                      <Button disabled={props.readonly} variant='imgBtn' startIcon={<AddCircleOutlineIcon/>} onClick={addRow} >Add Row</Button>
                    </div>
                  )}
                  setPageSize='auto'
                  muiTablePaperProps={{
                    sx:{
                      boxShadow:'none',
                      border: '1px solid #E3E3E3',
                      borderRadius:'0.75rem',
                      marginBottom:'1rem'
                    }
                  }}
                  muiTableHeadCellProps={{
                    sx:{
                      backgroundColor:'#F8F8F8 !important',
                      padding:'0.5rem !important',
                      '& .Mui-TableHeadCell-Content-Wrapper':{
                        fontSize:'0.75rem',
                        fontWeight:'600',
                        color:'#000',
                      },
                      '&:first-of-type':{
                        borderRadius: '0.5rem 0 0 0',
                        paddingLeft:'1.25rem !important'
                      },
                      '&:last-of-type':{
                        borderRadius: '0 0.5rem 0 0'
                      }
                    }
                  }}
                  muiTableHeadProps={{
                    sx:{
                      '& .MuiTableRow-root':{
                        backgroundColor:'transparent'
                      }
                    }
                  }}
                  muiBottomToolbarProps={{
                    sx:{
                      backgroundColor:'transparent',
                      fontSize:'0.75rem',
                      fontWeight:'500',
                      color:'#000',
                      fontFamily: ['Poppins','sans-serif',].join(','),
                    }
                  }}
                  muiTableBodyCellProps={{
                    sx:{
                      fontSize:'0.75rem !important',
                      fontWeight:'500',
                      color:'#000',
                      borderBottom: '1px solid #E3E3E3 !important',
                      fontFamily: `${['Poppins','sans-serif',].join(',')} !important`,
                      opacity:tableCell,
                      '&:first-of-type':{
                        paddingLeft:'1.25rem'
                      }
                    }
                  }}
                  muiTableBodyRowProps={{
                    sx:{
                      '&:hover .MuiTableCell-root':{
                        backgroundColor:'#F3F5F5'
                      }
                    }
                  }}
                />
              </Box>
            </div>
        </ThemeProvider>
      );
}

export default InvoiceTable2;