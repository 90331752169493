import React, {useEffect, useState} from 'react';
import { useBaseUrlContext } from '../../context/Base_url';

import {ThemeProvider, Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

import copyIcon from '../../assets/copy.svg';
import CompliantIcon from '../../assets/compli-alert.svg'

const APICredModal = ({openApiCredent, setOpenApiCredent, setOpenFail,setOpenSuccess, setTextSuccess, setTextFail, clientId, keyName, clientSecret, getApiCredData}) =>{

    const [uploading, setUploading] = useState(false)
    const {BaseUrl} = useBaseUrlContext()

    useEffect(()=>{
        setUploading(false)
    },[])

    const copyId = () => {
        navigator.clipboard.writeText(clientId).then(() => {
            setOpenSuccess(true)
            setTextSuccess('Text copied to clipboard!');
          }).catch((err) => {
            setOpenFail(true)
            setTextFail('Failed to copy text')
            console.error('Failed to copy text: ', err);
          });
    }

    const copySecret = () => {
        navigator.clipboard.writeText(clientSecret).then(() => {
            setOpenSuccess(true)
            setTextSuccess('Text copied to clipboard!');
          }).catch((err) => {
            setOpenFail(true)
            setTextFail('Failed to copy text')
            console.error('Failed to copy text: ', err);
          });
    }


    return(
        <ThemeProvider theme={ThemeCustom}>
        <div>
            <Modal
                open={openApiCredent}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']} apiCredential-modal`}> 
                    <form >
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                    Generate API Credentials
                                </Typography>
                                <div className='modal-mt-12'>
                                    <div className='alert-bar-modal'>
                                        <img src={CompliantIcon} alt='info'/>
                                        <div className='alert-text'>
                                            Heads up! 
                                            <div>The Client Secret will only be displayed once. Make sure to copy and store it securely.</div>
                                        </div>
                                    </div>
                                </div>         
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Key name
                                        </InputLabel>
                                        <OutlinedInput 
                                            id='name'
                                            placeholder='My test key'
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            disabled
                                            value={keyName}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Client ID
                                        </InputLabel>
                                        <OutlinedInput 
                                            id='clientId'
                                            placeholder='d1f8a3b4-9e8c-42d9-a1e5-7e5c2b8d6f3a'
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            value={clientId}
                                            disabled
                                            endAdornment={
                                                <InputAdornment position="end" className='modal-InputAdornment'>
                                                    <IconButton
                                                        aria-label="copy client id"
                                                        onClick={copyId}
                                                        edge="end"
                                                    >
                                                    {<img src={copyIcon} alt='copy' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Client secret
                                        </InputLabel>
                                        <OutlinedInput 
                                            id='clientSecret'
                                            placeholder='4yJ8eT1oQvZ3xC9wF7uM5sXaA1pL6vD2'
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            disabled
                                            value={clientSecret}
                                            endAdornment={
                                                <InputAdornment position="end" className='modal-InputAdornment'>
                                                    <IconButton
                                                        aria-label="copy client secret"
                                                        onClick={copySecret}
                                                        edge="end"
                                                    >
                                                    {<img src={copyIcon} alt='copy' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Okay" variant={'secondaryBtn'} classes='modal-button-12 w-modal-full' type="submit" onClick={() => {setOpenApiCredent(false); getApiCredData()}} disabled={uploading}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
        </ThemeProvider>
    );
}

export default APICredModal;