import React, {useEffect, useState} from 'react';
import {useLocation, Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/User_Access';
import { useBaseUrlContext } from '../../context/Base_url'

import {AppBar, Box, Toolbar, Container, Typography, IconButton, Avatar, Menu, MenuItem} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {ThemeProvider } from "@mui/material";

import LogoutModal from '../modal/Modal_logout';

import { ThemeCustom, ThemeMain } from "../../styles/Theme";
import NavStyle from './Nav_style.module.scss';
import ButtonOutline from '../button/Btn_outline';

import logo from '../../assets/Docupath-Logo.svg';
import logoTestEnv from '../../assets/logo-test-env.svg'



const NavBarSimple = (props) => {

  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const {devMode, userName, userEmail, orgName, accessControl} = useUserContext()
  const {env} = useBaseUrlContext()
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [auth0Logout, setAuth0Logout] = useState(true);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () =>{

    handleCloseUserMenu();
    setOpenLogout(true);

  }


  return (
    <ThemeProvider theme={ThemeCustom}>
      <LogoutModal setOpenLogout={setOpenLogout} openLogout={openLogout} auth0Logout={auth0Logout}/>
        <AppBar position="fixed" className={`bg-white shadow-none border-enable`}>
        <Container maxWidth="false">
            <Toolbar disableGutters className='flex justify-between'>
                <div className='flex items-center'>
                  <img src={env ? logoTestEnv:logo} alt='Logo' className={`${NavStyle['nav-img-width']} w-full`}/>
                </div>
                <div className="flex items-center">
                  {props.isUserLogged ? (
                    <Box sx={{ flexGrow: 0 }} className="flex items-center">
                        <Box component={'div'} onClick={handleOpenUserMenu} className='flex items-center cursor-pointer' >
                            <div className='flex flex-col'>
                              <Typography className='mr-6 hidden sm:block mb-1 text-sonicSilver text-right' variant="text14" component={'div'}>Logged in as</Typography>
                              <Typography className='mr-6 hidden sm:block' variant="textBase" component={'div'}>{userEmail}</Typography>
                            </div>
                            <IconButton sx={{ p: 0, '&:hover':{backgroundColor:'transparent'} }} disableTouchRipple>
                                <Avatar alt={userName} src="/static/images/avatar/1.jpg" className='bg-primary' />
                                <ArrowDropDownIcon />
                            </IconButton>
                        </Box>
                        <Menu
                          sx={{ mt: '45px', borderRadius:'1.25rem' }}
                          id="menu-navBar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                          className='main-nav'
                        >   
                            <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item-wrapper']} ${NavStyle['nav-item-text-18']}`}>
                              {userName}
                            </Typography>
                            <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item-wrapper']} ${NavStyle['nav-text-sonicSilver']} ${NavStyle['nav-item-text']}`}>
                              {userEmail}
                            </Typography>
                            {props.hasTenant?(
                              <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item-wrapper']} ${NavStyle['nav-item-text-gray']} ${NavStyle['nav-item-border-b-0']}`}>
                                {orgName}
                              </Typography>
                              ):""
                            }
                            <MenuItem onClick={logout} className={`${NavStyle['nav-item-wrapper']} ${NavStyle['nav-item-logout-only']} main-nav-item `} >
                                <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item']} ${NavStyle['nav-item-capitalize']}`}>
                                  Sign out
                                </Typography>
                            </MenuItem>
                        </Menu> 
                    </Box>
                    
                  ) : (
                    <ButtonOutline btnName="X" variant={'primaryOutline'} type="button" classes='p-2 min-h-[auto] min-w-[2.5rem] min-h-[2.5rem] rounded-lg'  onClick={()=>navigate(-1)} disabled={false}/>
                  )}
                </div>
                
            </Toolbar>
        </Container>
        </AppBar>
    </ThemeProvider>    
  );
}
export default NavBarSimple;