import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Avatar, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklButton = () => {


    return(
        <ThemeProvider theme={ThemeCustom}>
            <Skeleton animation="wave" className={` min-w-[12.18rem] max-h-[3.449rem] h-[3.449rem] rounded-xl`}></Skeleton>
        </ThemeProvider>
        
    )
}

export default SklButton;