import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/User_Access';

import {Skeleton} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SimpleBar from 'simplebar-react'; 

import 'simplebar-react/dist/simplebar.min.css';

import FormInputMap from './Form_input_map';
import XmlViewer from './XML_viewer';
import SklXmlViewer from '../skeletons/Skl_xmlviewer';

import WarningIcon from '../../assets/bold-danger.svg'

    
const OutputTab = (props) => {

    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const simpleBarRef = useRef(null);
    const navigate = useNavigate();
    const [sklCommon, setSklCommon] = useState(true);
    const {devMode, accessControl} = useUserContext()

    //page skeleton handiling

    useEffect(() => {

        //console.clear()
        props.setSklXmlView(true)
        props.setSklFormView(true)
        setTimeout(()=>{
           setSklCommon(false)
        },200)

    },[]) 

    useEffect(()=>{console.log('#######',props.sklXmlView)},[props.sklXmlView])

    //open known map

    const goToSettings = () => { 
        console.log('goToSettings');
        if(devMode){
            navigate('/settings/known_mappings?devMode=true')
        }
        else{
            navigate('/settings/known_mappings')
        }
    }

    //xml viewer relates

    useEffect(() => {

        if (props.destinationFormat.length > 0) {
          const filteredItems = props.destinationFormat.map(item => {
            if(searchText !== ''){
                if (item.line.includes(searchText)) {
                    return { ...item, class: 'color' };
                  }
            }
            return item;
          });
    
          //console.log('filteredItems', filteredItems);
          setFilteredData(filteredItems);
        }
    
        //console.log('searchText', searchText);
    }, [searchText, props.destinationFormat]);

    //auto scroll to selected line

    const handleScroll = (offsetTop) => {
        if (simpleBarRef.current) {
          simpleBarRef.current.getScrollElement().scrollTop = offsetTop;
        }
    };
   
    return(
        <Grid container spacing={2}>
        <Grid xs={12} md={6} className="md:border-r-2 border-0 md:border-solid md:border-r-cultured p-0 ">
            <div className={`m-5 border-solid border border-cultured rounded-xl bg-white`}>
                {props.disconnectedCount > 0  ? (
                    <div className='p-5 m-0 flex flex-row justify-between'>
                        {sklCommon?(
                            <Skeleton animation="wave" className={`w-[34.75rem] max-h-[2.75rem] h-[2.75rem] rounded-xl`}></Skeleton>
                        ):(
                            <div className='text-sealBrown bg-seashell py-3 px-5 text-sm rounded-xl flex items-center font-medium w-full '>
                                <img src={WarningIcon} alt='warning' className='max-w-[1.25rem] w-full mr-3'/>  
                                {props.disconnectedCount} tags disconnected 
                                {Object.keys(accessControl).includes('/known_mappings') && accessControl['/known_mappings'].write ? (
                                <span onClick={goToSettings} className='ml-1 underline cursor-pointer font-semibold'>Map Now</span>
                                ):'Please contact an administrator to resolve this issue.'}
                            </div>
                        )}
                    </div>
                ) : (<div className='pt-5'></div>)
                }
                <SimpleBar className={`${props.disconnectedCount > 0?'min-height-grid-map-field':'min-height-grid'}`}>
                    <FormInputMap
                      jsonInvoice={props.jsonInvoice}
                      mapTableRow={props.mapTableRow}
                      setMapTableRow={props.setMapTableRow}
                      readonly={props.readonly}
                      setSearchText={setSearchText}    
                      disconnectedCount={props.disconnectedCount}     
                      mapTableData={props.mapTableData}
                      setMapTableData={props.setMapTableData}   
                      sklFormView={props.sklFormView}
                      setSklFormView={props.setSklFormView}
                    />
                </SimpleBar>
            </div>
        </Grid>
        <Grid xs={12} md={6} className='p-0 mb-[6rem] md:mb-0'>
            {!props.sklXmlView ? (
                <div className='m-5 border-solid border border-borderGrayDark rounded-xl relative scroll-light'>
                    <SimpleBar className='min-height-grid bg-primary rounded-xl' ref={simpleBarRef}>
                        <XmlViewer 
                            nestedMap={filteredData}
                            handleScroll={handleScroll}
                        />
                    </SimpleBar>               
                </div>
            ):(
                <div className='min-height-grid m-5 border-solid border border-borderGrayDark rounded-xl bg-white overflow-hidden'>
                    <SklXmlViewer/>
                </div>    
            )}
        </Grid>
    </Grid>
        
    )
};
    
export default OutputTab;