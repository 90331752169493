import React, {useEffect, useState} from 'react';

import {Box, Alert, IconButton, Collapse} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import CompliantIcon from '../../assets/compli-alert.svg'


const CompliantAlert = (props) =>{
    

    return (
        <Box className='max-w-[60vw] w-full mb-3 mx-auto'>
          <Collapse in={props.openCompliant}>
            <Alert className='bg-latte text-primary text-xs font-semibold	px-4 py-[0.13rem] rounded-xl font-sans compliant-alert'
              variant="filled"
              action={
                <IconButton className='text-primary p-[0.325rem]'
                  aria-label="close"
                  onClick={() => {
                    props.setOpenCompliant(false);
                  }}
                >
                  <CloseIcon className='w-5 h-5'/>
                </IconButton>
              }
              //icon={<CheckCircleOutlinedIcon className='w-6 h-6'/>}
              icon={<img src={CompliantIcon} alt='icon' className='w-6 h-6'/>}
            >
              {props.alretText} 
            </Alert>
          </Collapse>
          
        </Box>
      );
}

export default CompliantAlert;