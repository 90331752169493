import React from "react";

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

import NotFoundGif  from "../../assets/not-found.gif";


const NotFound = (props) => {
    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className={`w-full ${props.from === 'instruct' ? 'min-h-instruct':'min-height-uploadView'} flex flex-col justify-center items-center`}>
                <img className="max-w-[15.625rem] min-h-[15.625rem]" src={NotFoundGif} alt="search logo"/>
                <Typography variant="text30" className="font-semibold mb-7" component='div'>
                    {props.msgText}
                </Typography>
            </div>
        </ThemeProvider>    
    );
}

export default NotFound;