import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Avatar, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

const SklDocUploadFull = () => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='flex justify-center items-center flex-col w-[75vw]'>
                <div className="flex justify-center items-center flex-col w-[75vw]">
                    <Skeleton animation="wave" className='w-full max-w-[10rem] h-[10rem] rounded mt-10 mb-12' ></Skeleton>
                    <Skeleton animation="wave" className='w-full max-w-[17.5rem] h-[2.25rem] rounded mb-7' ></Skeleton>
                    <Skeleton animation="wave" className='w-full max-w-[45rem] h-[1.35rem] rounded mb-10' ></Skeleton>
                </div>
                <div className='max-w-[22.5rem] mx-auto'>
                    <div className="drag-text-wrapper">
                        <Skeleton animation="wave" className={` min-w-[22.5rem] max-h-[3.449rem] h-[3.449rem] rounded-xl`}></Skeleton>
                    </div>
                </div>
                <Skeleton animation="wave" className='w-full max-w-[28rem] h-[0.125rem] rounded mt-10' ></Skeleton>
                <Skeleton animation="wave" className='w-full max-w-[25rem] h-[1.35rem] rounded mt-10' ></Skeleton>
            </div>
        </ThemeProvider>
    )
}

export default SklDocUploadFull;