import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";

const ButtonImg = (props) => {
    return (
        <ThemeProvider theme={ThemeCustom}>
          <Button variant="primaryBtn" className={`${props.classes} min-w-[4rem] lg:min-w-[11.5rem]`} onClick={props.onClick}>
            <img src={props.img} alt="icon" width={24} className="lg:me-3 max-w-[1.5rem]"/>
            <span className='hidden lg:block'>{props.btnName}</span></Button>
        </ThemeProvider>
    );
}

export default ButtonImg;