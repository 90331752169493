import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Typography, Skeleton, FormControl, InputLabel, OutlinedInput, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklApproveForm = () => {


    return(
        <ThemeProvider theme={ThemeCustom}>
            <div>
                <div className='input-container flex flex-wrap'>
                    {[1,2,3,4].map((block,index) => (
                        <div key={index} className='w-1/2 border-b border-0 border-solid border-b-borderGrayDark input-row'>
                            <div className='section p-5'>
                                <div className='mb-5 flex justify-between items-center'>
                                    <Skeleton animation="wave" className={` w-[8.75rem] h-[1.25rem] rounded`}></Skeleton>    
                                </div>
                                {[1,2,3,4,5,6,7].map((input,i) => (
                                <FormControl variant="standard" fullWidth key={i} margin="normal" className='flex flex-row items-center my-1'>
                                    <Skeleton animation="wave" className={` w-1/4 h-[1rem] rounded mr-3`}></Skeleton>    
                                    <Skeleton animation="wave" className={` w-2/3 h-[1.5rem] rounded-md`}></Skeleton>    
                                </FormControl>
                                ))}
                            </div>
                        </div>
                    ))} 
                </div>
                    <div className='p-5 pb-0 flex justify-between items-center border-t border-0 border-solid border-t-borderGrayDark'>
                        <Skeleton animation="wave" className={` w-[8.75rem] h-[1.25rem] rounded`}></Skeleton>    
                    </div>
                    <div  className='p-5'>
                        <TableContainer className='border border-solid border-borderGrayDark rounded-xl'>
                            <Table stickyHeader aria-label="table skeleton border border-solid border-borderGrayDark">  
                                <TableHead>
                                    <TableRow className='h-[2.5rem]'>
                                        <TableCell className='py-0 h-[2.5rem]'>
                                            <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                                        </TableCell>
                                        <TableCell className='py-0 h-[2.5rem]'>
                                            <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                                        </TableCell>
                                        <TableCell className='rounded-tr-xl w-[12vw] py-0 h-[2.5rem]'>
                                            <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='h-[2.5rem]'>
                                        <TableCell>
                                            <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                                        </TableCell>
                                        <TableCell>
                                            <div className='flex'>
                                                <div className='mr-4'>
                                                    <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] rounded`}></Skeleton>    
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
            </div>
        </ThemeProvider>
        
    )
}

export default SklApproveForm;