import React, {useState, useEffect, useRef} from 'react';
import { useUserContext } from '../../context/User_Access';

import {ThemeProvider, IconButton, Collapse, Typography} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';

import InvoiceInput from '../invoice-input/Invoice-input';
import InvoiceTable2 from '../invoice-input/invoice-table-2';
import AddDropdown from '../invoice-input/Add_dropdown';
import SklApproveForm from '../skeletons/Skl_approve-form';

const FormInput = (props) => {

    const {devMode} = useUserContext()
    const debounceTimeout = useRef(null);
 

    //create table columns - formatting ###

    const colCreate = (col) => {
        
        //console.log('colCreate > ', col)
        
        return col.map(item => ({
            accessorKey: item,
            header: item,
            id: item
        }))   

    }

    //create table rows - formatting ####

    const rowCreate = (row) => {

        //console.log('###ROW',row)

        return row.map((item, index)=> {

            const rowFormatted = {};

            for (const key in item) {                
                if (item[key] && typeof item[key] === 'object' && 'value' in item[key]) {
                    rowFormatted[key] = item[key].value;
                } else {
                    rowFormatted[key] = item[key];
                }
            }

            return rowFormatted

        })     

    }    


    //update the jsonInvoiceText when inputs change ###
    
    const handleTextInputChange = (key, name, value, status, isUpdated, defValue, type) => {

        //console.log('handleTextInputChange')
    
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
    
        debounceTimeout.current = setTimeout(() => {
          props.setJsonInvoiceText(prevState => {
            const index = prevState.findIndex(item => item.id === key);
            const updatedItem = {
              ...prevState[index],
              [name]: { value, status, IsUpdated: isUpdated, defValue, type }
            };
            const updatedJsonInvoiceText = [
              ...prevState.slice(0, index),
              updatedItem,
              ...prevState.slice(index + 1)
            ];
            return updatedJsonInvoiceText;
          });
          props.setSearchText(value);
          //console.log('debounceTimeout')

        }, 1000);
        
    };

      
    //switch buyer and seller ???

    const switchData = () => {

        const dataSwitch = [...props.jsonInvoiceText];
        const tempKey = dataSwitch[0].Key; // Preserve line key
        dataSwitch[0].Key = dataSwitch[1].Key;
        dataSwitch[1].Key = tempKey;

        [dataSwitch[0], dataSwitch[1]] = [dataSwitch[1], dataSwitch[0]];
        props.setJsonInvoiceText(dataSwitch);
        props.setJsonInvoiceTextOriginal(dataSwitch)

    }


    //date convert ###

    const getDate = (timeStampDate) => {

        const timestamp = timeStampDate;
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Format the date
        const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        };

        return date.toLocaleDateString('en-US', options);

    }

    //time convert ###

    const getTime = (timeStampTime) => {

        // Convert Unix timestamp to milliseconds
        const date = new Date(timeStampTime * 1000);

        // Get hours and minutes in 12-hour format
        const hours = date.getHours() % 12 || 12;
        const minutes = ('0' + date.getMinutes()).slice(-2);

        // Determine AM/PM
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

        // Format the time
        return `${hours}:${minutes} ${ampm}`;

    }

    //form view skeleton handiling ###

    useEffect(()=>{
        
        if(props.jsonInvoiceTextOriginal.length > 0 && props.jsonInvoiceTable.length > 0){
            props.setSklFormView(false)
        }

    },[props.jsonInvoiceTable, props.jsonInvoiceTextOriginal])

    //debug

    useEffect(()=>{

        //console.log('props',props)

    },[props])

    
    return(
        <>
            <ThemeProvider theme={ThemeCustom}>
                <div>
                {((props.jsonInvoiceTable.length > 0 || props.jsonInvoiceTextOriginal.length > 0) && !props.sklFormView ) ? ( 
                    <>
                        <form id='form'>
                            <div className='input-container flex flex-wrap'>
                                    { props.jsonInvoiceTextOriginal.length > 0 && props.jsonInvoiceTextOriginal.map((item, index)=> (
                                    <React.Fragment key={index}>
                                        <div className='w-1/2 border-b border-0 border-solid border-b-borderGrayDark input-row' key={index}>
                                            <div className='section p-5'>
                                                <div className='mb-5 flex justify-between items-center'>
                                                    <Typography variant='textBase' className='font-semibold'>{(item.Key).replace(/([A-Z][a-z])/g, ' $1').trim()}</Typography>
                                                    {devMode ? (
                                                        <AddDropdown 
                                                            readonly={props.readonly} 
                                                            keyCat={item.Key} 
                                                            setAddField={props.setAddField} 
                                                            addField={props.addField} 
                                                            fieldNameList={props.fieldNameList}
                                                            setOpenCommingSoon={props.setOpenCommingSoon}
                                                        />
                                                    ):''}
                                                </div>
                                                {Object.entries(item).map(([name, value], i)=> (
                                                    <React.Fragment key={i}>
                                                        {(name !== 'Key' && name !== 'id') ? (
                                                            <InvoiceInput 
                                                                inputName={name} 
                                                                inputType={'text'}  
                                                                id={item.Key+i}
                                                                value={value.value} 
                                                                compliant={value.status}
                                                                onInputChange={(newValue, updated) => handleTextInputChange(item.id, name, newValue, value.status, updated, value.defValue, value.type)}
                                                                readonly={props.readonly}
                                                                setSearchText={props.setSearchText}
                                                                setFormModify={props.setFormModify}
                                                                defValue={value.defValue}
                                                                fieldType={value.type}
                                                                inputRef={el => { props.inputRefs.current[`${item.id}_${name}`] = el; }} 
                                                                />
                                                            ):(
                                                            ''
                                                        )}
                                                    </React.Fragment>  
                                                ))}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    ))}
                            </div>

                            {props.jsonInvoiceTable.length > 0 && props.jsonInvoiceTable.map((table, index) => (
                                <React.Fragment key={table.Key}>
                                    <div className='p-5 pb-0 flex justify-between items-center border-t-2 border-0 border-solid border-t-cultured'>
                                        <Typography variant='textBase' className='font-bold'>{ table.Key === 'Items' ? 'Line Items' : table.Key}</Typography>
                                    </div>
                                    <InvoiceTable2 
                                        tableArray={table.tableRows ? rowCreate(table.tableRows) : null} 
                                        jsonInvoiceTable={props.jsonInvoiceTable}
                                        setJsonInvoiceTable={props.setJsonInvoiceTable}
                                        columns={table.tableColumns ? colCreate(table.tableColumns) : null} 
                                        readonly={props.readonly}
                                        setSearchText={props.setSearchText}
                                        tablePosition={index}
                                        //tableKey={table.length > 0 && table.Key}
                                        setFormModify={props.setFormModify}
                                    />
                                </React.Fragment>
                            ))}

                            {props.jsonInvoiceBarcode.length > 0 ? (
                                <div className='pb-3'>
                                    <div className='p-5 pb-0 flex justify-between items-center border-t border-0 border-solid border-t-borderGrayDark'>
                                        <Typography variant='textBase' className='font-bold'>Barcodes</Typography>
                                    </div>
                                        <div className='w-full p-5 pb-0'>
                                            {props.jsonInvoiceBarcode.map((barcode,index)=>(
                                                <div className='mb-3 flex justify-start items-start' key={index}>
                                                    <div className='mr-1'>&gt;</div>
                                                    <Typography variant='text14' className='break-all' component={'div'}>{barcode}</Typography>
                                                </div>
                                                            
                                            ))}
                                        </div>
                                </div>
                            ):''}

                            {props.changeLogData.length > 0 ? (
                                <div className='pb-5'>
                                    <div className='p-5 pb-0 flex justify-between items-center border-t border-0 border-solid border-t-borderGrayDark'>
                                        <Typography variant='textBase' className='font-bold'>Change log</Typography>
                                    </div>
                                    <div className='w-full p-2 pt-5'>
                                        <div className='w-full border border-solid border-borderGrayDark rounded-xl'>
                                            {props.changeLogData.map((log,index)=>(
                                                <div className='log-container py-5 px-4 items-center' key={log.time+log.date}>
                                                    <div className='flex justify-between mb-4'>
                                                        <Typography variant='text14' className='' component={'div'}><span className='font-bold'>{log.user.name}</span> made <span className='font-bold'>{log.numberOfChanges}</span> {`new change${log.numberOfChanges>1?'s':''}`}</Typography>
                                                        <Typography variant='text14' className='text-sonicSilver' component={'div'}>{getDate(log.date)} • {getTime(log.time)}</Typography>
                                                    </div>
                                                    {/* <div className='flex items-center mb-2 log-item'>
                                                        <div className='mr-1'>&gt;</div>
                                                        <Typography variant='text14' className='' component={'div'}>The <span className='font-bold'>{log.changes.at(0).changedField}</span> was changed from <span className='font-bold'>CBA Electronics</span> to <span className='font-bold'>ABC Electronics</span></Typography>
                                                    </div> */}
                                                    {log.changes.map((logChange,i)=>(
                                                    <div className='flex items-center log-item' key={log.time+log.date+i}>
                                                        <div className='mr-1'>&gt;</div>
                                                        {logChange.isTable ? (
                                                            <Typography variant='text14' className='' component={'div'}>The <span className='font-bold'>{logChange.changedSection}</span> - <span className='font-bold'>{logChange.changedRow} row</span> - <span className='font-bold'>{logChange.changedColumn}</span> was changed from <span className='font-bold'>{logChange.previousValue === '' ?  '""': `"${logChange.previousValue}"` }</span> to <span className='font-bold'>{logChange.newValue === '' ?  '""': `"${logChange.newValue}"` }</span></Typography>
                                                        ):(
                                                            <Typography variant='text14' className='' component={'div'}>The <span className='font-bold'>{logChange.changedSection}</span> - <span className='font-bold'>{logChange.changedField}</span> was changed from <span className='font-bold'>{logChange.previousValue === '' ?  '""': `"${logChange.previousValue}"` }</span> to <span className='font-bold'>{logChange.newValue === '' ?  '""': `"${logChange.newValue}"`}</span></Typography>
                                                        )}
                                                    </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ):''}    
                        </form>
                    </>
                ):(
                    <SklApproveForm/>
                )}
                </div>
            </ThemeProvider>
        </>
    )
}

export default FormInput;