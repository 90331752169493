import React, {useEffect, useState} from 'react';
import { useBaseUrlContext } from '../../context/Base_url';
import axios from "axios";
import Cookies from 'js-cookie';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';

import deleteIcon from '../../assets/delete-modal.gif';


const DeleteModal = ({openDelete, setOpenDelete, delType, modalText, selectedId, deleteDataRow, selected, deleteMutiple, from, setDeleting, delTypeText, selectedDelete, removeItemFromCache}) =>{
    
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false);

    const deletefileAPI = async() => {

        if(from === 'filePreview'){

            deleteMutiple();
            setOpenDelete(false);

        }
        else{

            console.log('del file', selectedId,'delete files', selected, delType);
            setDeleting(true);
            setBtnLoading(true)

            if(delType !== null && delType === 1){
                console.log('ccccccxxx',selectedId)

                let deleteId = JSON.stringify({
                    "documentId": [
                      `${selectedId}`
                    ]
                });

                try{

                    const res = await axiosInstance.delete(`/document.php`,{data : deleteId})
        
                    console.log('delete success', res);

                    setOpenDelete(false);
                    deleteDataRow();
                    removeItemFromCache(selectedId)
                    setDeleting(false);
                    setBtnLoading(false);
                }
                catch (err) {
                    console.error('Upload failed:', err);
                    setDeleting(false);
                    setBtnLoading(false);
                }
                    
            }
            else{

                let deleteIds = JSON.stringify({
                    "documentId": selectedDelete
                });

                try{

                    const res = await axiosInstance.delete(`/document.php`,{data:deleteIds})
    
                    console.log('delete success', res);

                    setOpenDelete(false);
                    deleteDataRow();
                    removeItemFromCache(selectedDelete)
                    setDeleting(false);
                    setBtnLoading(false);

                }
                catch (err) {
                    setDeleting(false);
                    setBtnLoading(false);
                    console.error('Upload failed:', err);
                }

            }
        }

    }

    return(
        <div>
            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-delete']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={deleteIcon} alt='delete icon' className={ModalStyle["icon-img"]}/>
                            </div>
                            { delType !== null && delType === 1 ? (
                                <>
                                    <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                        Are you sure you want to delete this {delTypeText === 'API' ? 'API':'file'}?
                                    </Typography>
                                    <Typography id="modal-modal-description" className={ModalStyle['delete-file']} align={'center'} variant="textBase" component="p">
                                        {modalText}
                                    </Typography>
                                </>
                            ) : (
                                <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                        Are you sure you want to delete selected {modalText} file{modalText == 1 ? "":"s"}?
                                </Typography>
                            )
                                
                            }
                            
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Cancel" type="button" variant={'platinumBtn'} classes="mr2" onClick={() => setOpenDelete(false)}/>
                            <ButtonSecondaryLoader variant={'redBtn'} type='button' btnName='Delete' btnLoading={btnLoading} disabled={false} onClick={deletefileAPI}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default DeleteModal;